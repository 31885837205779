import React from "react";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";

const EntertainmentSearch = ({ x, room_uuid, url }) => {
  const navigate = useNavigate();

  return (
    <div id={x.name} key={x.id}>
      <Zoom>
        <div
          className="con-img-container v3"
          onClick={() => navigate(`/${room_uuid}/${url}/${x.uuid}`)}
        >
          <div className="con-img">
            <img src={x.image} alt="" />
            <p></p>
          </div>
        </div>
        <div className="con-text-container">
          <p>{x.name}</p>
          <hr className="mb-0 dark" />
        </div>
      </Zoom>
    </div>
  );
};

export default EntertainmentSearch;
