import React from "react";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";

const PharmacySearch = ({ pharmacie, room_uuid, url }) => {
  const navigate = useNavigate();

  return (
    <div key={pharmacie.id}>
      <Zoom>
        <div
          className="con-img-container v3"
          onClick={() =>
            navigate(
              `/${room_uuid}/${url}/category/${pharmacie.uuid}/id/${pharmacie.id}`
            )
          }
        >
          <div className="con-img">
            <img src={pharmacie.image} alt="" />
            <p></p>
          </div>
        </div>
        <div className="con-text-container">
          <p>{pharmacie.name}</p>
          <hr className="mb-0 dark" />
        </div>
      </Zoom>
    </div>
  );
};

export default PharmacySearch;
