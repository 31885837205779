import React, { useContext, useEffect, useRef, useState } from "react";
import HeaderInner from "../../Components/HeaderInner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Iframe from "react-iframe";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { ThemeContext } from "../../context/ThemeContext";

function Taxi() {
  const navigate = useNavigate();
  const [loading, setLaoding] = useState(false);

  const [dataInfo, setDataInfo] = useState(null);

  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    const getData = async () => {
      setLaoding(true);
      axios
        .get(`/${room_uuid}/location`)
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            setDataInfo(res.data.data);
            setLaoding(false);
          }
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={"Taxi booking"} />
      ) : (
        <HeaderInner headerName={"Taxi booking"} />
      )}
      <main className="home-main bg-white back-white book-table pad-top-0 taxi_book">
        {loading ? (
          <SpaLoader />
        ) : (
          <>
            {isLoading && (
              <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
                <div className="loader"></div>

              </div>

            )}
            <Iframe
              url={`https://dtcbooking.mobilityae.com/signin#/partner/660a628c9b2a1234a4f340ad?room=${dataInfo?.uuid}&lat=${dataInfo?.lat}&lng=${dataInfo?.long}`}
              width="100%"
              id=""
              // className="iframe_layar"
              display="block"
              onLoad={handleLoad}

            />
          </>

        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default Taxi;
