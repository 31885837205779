import React from "react";
import CarosleRestaurant from "../CarosleRestaurant";
import { Link } from "react-router-dom";
import { Bounce } from "react-reveal";
import BookingCarosle from "../BookingCarosle";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

const RResturants = ({ restaurant, room_uuid, url, theme }) => {
  return (
    <div
      className={`general-post-container restaurant-post booking-card ${
        restaurant.is_closed ? "closed restaurant-option" : "restaurant-option"
      }`}
      key={restaurant.id}
    >
      <Bounce left>
        {theme ? (
          <div className="box_food">
            <Link
              to={`/${room_uuid}/food-and-beverages/restaurant-booking/${restaurant.uuid}`}
            >
              <img src={restaurant?.image} alt="" className="booking_img" />
              <img src={Overllay} alt="" className="overlay" />
              <div className="text_food">
                <h5 className="">{restaurant.name}</h5>
                <p>
                  {restaurant?.cuisines?.map((cuisine, idx) => {
                    if (idx + 1 < restaurant?.cuisines.length) {
                      return `${cuisine.name}  |  `;
                    } else {
                      return `${cuisine.name}  `;
                    }
                  })}
                </p>
              </div>{" "}
            </Link>
          </div>
        ) : (
          <Link
            to={`/${room_uuid}/${url}/${restaurant.uuid}/${restaurant.id}`}
            className="general-post"
          >
            <div className="closed-tag"></div>
            {restaurant?.is_closed && (
              <span className="open_at">
                {restaurant.opens_at
                  ? `Opens at ${restaurant.opens_at}`
                  : `Closed`}
              </span>
            )}
            <div className="img-pnl ">
              <BookingCarosle
                noAuto={true}
                images={
                  restaurant.real_video
                    ? [{ image: restaurant.real_video }, ...restaurant.images]
                    : restaurant.images.length == 0
                    ? [{ image: restaurant.image }]
                    : restaurant.images
                }
                isVideo={restaurant.real_video ? true : false}
              />
            </div>
            <div className="txt-pnl">
              <h2>{restaurant.name}</h2>
              <p>
                {restaurant?.cuisines?.map((cuisine, idx) => {
                  if (idx + 1 < restaurant?.cuisines.length) {
                    return `${cuisine.name}  |  `;
                  } else {
                    return `${cuisine.name}  `;
                  }
                })}
              </p>
            </div>
          </Link>
        )}
      </Bounce>
    </div>
  );
};

export default RResturants;
