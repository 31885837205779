import React from 'react'
import { Link } from 'react-router-dom'

const PharmacySubMenuLoader = () => {
    return (
        <>
            <div className="add-post-container add-cart">
                <div className="add-cart-post new spa ">
                    <div className="add-cart-post-inner">
                        <div className="animated-background bg-skimmer"></div>
                        <span
                            className="img-pnl navigate"

                        >
                            <img src="" />
                        </span>
                        <div
                            className="txt-pnl navigate"

                        // onClick={() => navigate("/SpaAddService")}
                        >
                            <h2></h2>
                            <p></p>
                            <div className="flex-div align-item-center">
                                <h3></h3>
                                <Link
                                    to=""
                                    className="reg-btn service"
                                >
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-post-container add-cart">
                <div className="add-cart-post new spa ">
                    <div className="add-cart-post-inner">
                        <div className="animated-background bg-skimmer"></div>
                        <span
                            className="img-pnl navigate"

                        >
                            <img src="" />
                        </span>
                        <div
                            className="txt-pnl navigate"

                        // onClick={() => navigate("/SpaAddService")}
                        >
                            <h2></h2>
                            <p></p>
                            <div className="flex-div align-item-center">
                                <h3></h3>
                                <Link
                                    to=""
                                    className="reg-btn service"
                                >
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-post-container add-cart">
                <div className="add-cart-post new spa ">
                    <div className="add-cart-post-inner">
                        <div className="animated-background bg-skimmer"></div>
                        <span
                            className="img-pnl navigate"

                        >
                            <img src="" />
                        </span>
                        <div
                            className="txt-pnl navigate"

                        // onClick={() => navigate("/SpaAddService")}
                        >
                            <h2></h2>
                            <p></p>
                            <div className="flex-div align-item-center">
                                <h3></h3>
                                <Link
                                    to=""
                                    className="reg-btn service"
                                >
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-post-container add-cart">
                <div className="add-cart-post new spa ">
                    <div className="add-cart-post-inner">
                        <div className="animated-background bg-skimmer"></div>
                        <span
                            className="img-pnl navigate"

                        >
                            <img src="" />
                        </span>
                        <div
                            className="txt-pnl navigate"

                        // onClick={() => navigate("/SpaAddService")}
                        >
                            <h2></h2>
                            <p></p>
                            <div className="flex-div align-item-center">
                                <h3></h3>
                                <Link
                                    to=""
                                    className="reg-btn service"
                                >
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PharmacySubMenuLoader