import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import { Button, Col, Form, Row } from "react-bootstrap";
import Post from "../../assets/images/Spa/Post-3.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "../../axios";
import { array, number, object, string } from "yup";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Toast } from "primereact/toast";
import ReactGA from "react-ga4";
import "../../new-pages/hotel-services/HotelServices.scss";
import { useTranslation } from "react-i18next";
import TaxInclusive from "../../Components/TaxInclusive.";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { Calendar } from "primereact/calendar";
import CalendarComponent from "../../Components/Calender/Calender";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import { Data } from "@react-google-maps/api";
import { ThemeContext } from "../../context/ThemeContext";

function SpaAddService() {
  const toast = useRef(null);

  const [enableClass, setEnableClass] = useState(true);

  const [phone_number_required, setphone_number_required] = useState(false);
  const [price_expression, setPrice_expression] = useState();
  const [timeInput, setTimeInput] = useState(new Date());
  const [currHoursPlusThree, setCurrHoursPlusThree] = useState();
  const [dateInput, setDateInput] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);
  const [loadign, setLoadign] = useState(false);
  const [bookLoadign, setBookLoadign] = useState(false);
  const [service, setService] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedPrice, setSelectedPrice] = useState();
  const [spaID, setSpaID] = useState();
  const [working_times, setWorking_times] = useState();
  const [day_name, setDay_name] = useState();
  const [duration, setDuration] = useState("");
  const [therapists, setTherapists] = useState();
  const [phone, setPhone] = useState();
  const [hourShow, sethourShow] = useState("");
  const [minShow, setMinShow] = useState("");
  // const [start_time, setStart_time] = useState
  const [spaService, setSpaService] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const currency = localStorage.getItem("currency");

  const checkCurrentTimeWithMinTimeTheSpa = (cr_time) => { };

  useEffect(() => {
    const afterThreeHours = new Date().getHours() + 4
    console.log("afterThreeHours000000000000000000", afterThreeHours + 4);
    const time = `${afterThreeHours.toString()
      .padStart(2, "0")}:${new Date(timeInput)
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

    setTimeInput(time);
    console.log("time 000", time);
    console.log("hourShow 000", hourShow);
    console.log("minShow 000", minShow);
    const time_ = time.split(":");
    console.log("time_[0]", time_[0]);

    sethourShow(time_[0]);
    setMinShow(time_[1]);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const id = params.spa_id;
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const spa_uuid = rawPath.split("spa/")[1];
  // const theme = location.search;
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const room_number = localStorage.getItem("room_number");
  setTimeout(() => {
    setEnableClass(false);
  }, 3000);

  const tineRif = useRef();

  const handleTime = (e) => {
    const currentDay = new Date().toLocaleDateString();
    let time = e.target.value;

    console.log("Selected time:", time);

    // Convert 12-hour to 24-hour if needed
    const is12HourFormat = time.includes("PM") || time.includes("AM");
    if (is12HourFormat) {
      const timeParts = time.split(" ");
      let [hours, minutes] = timeParts[0].split(":");
      const period = timeParts[1];

      if (period === "PM" && hours !== "12") {
        hours = parseInt(hours) + 12;
      }
      if (period === "AM" && hours === "12") {
        hours = "00";
      }
      time = `${hours}:${minutes}`;
    }

    const time_ = time.split(":");
    let hour = parseInt(time_[0]);
    let minutes = time_[1];

    let afterThreeHours = new Date().getHours() + 4;
    // if (afterThreeHours >= 24) {
    //   afterThreeHours = afterThreeHours - 24;
    // }

    console.log("Current time + 3 hours:", afterThreeHours);

    if (new Date(dateInput).toLocaleDateString() === currentDay) {
      if (hour >= afterThreeHours) {
        setTimeInput(`${hour}:${minutes}`);
        sethourShow(hour);
        setMinShow(minutes);
      } else {
        setTimeInput(`${afterThreeHours}:${minutes}`);
        sethourShow(afterThreeHours);
        setMinShow(minutes);
      }
    } else {
      setTimeInput(time);
      sethourShow(hour);
      setMinShow(minutes);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpaService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [errName, setErrName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errPhone, setErrPhone] = useState("");

  const checkValEmail =
    spaService.email.length > 0 && !/\S+@\S+\.\S+/.test(spaService.email);
  const checkInputs = () => {
    if (spaService.name == "") {
      setErrName("The name field is required.");
    } else {
      setErrName("");
    }
    if (spaService.email.length > 0 && !/\S+@\S+\.\S+/.test(spaService.email)) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }
    if (phone_number_required) {

      if (spaService.phone == "") {
        setErrPhone("The phone field is required.");
      } else {
        setErrPhone("");
      }
    }
  };

  useEffect(() => {
    const currentTime = new Date();

    // Format the time as HH:mm (hours and minutes)
    let hours = currentTime.getHours().toString().padStart(2, "0");

    const minutes = currentTime.getMinutes().toString().padStart(2, "0");

    const currentDate = new Date();
    // Format the date as YYYY-MM-DD (required by input type="date")

    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    if (hours == "24") {
      hours = "01";
      day = day + 1;
    }

    const formattedTime = `${hours}:${minutes}`;
    const formatDate = `${year}-${month}-${day}`;
    // setDateInput(formatDate);
    // setTimeInput(formattedTime);
    // setCurrHoursPlusThree(formattedTime);
    // setTimeInput(formattedTime);
    // checkCurrentTimeWithMinTimeTheSpa();
  }, []);
  const currentTimem = new Date(`${dateInput}T${timeInput}`);
  const dubaiTime = currentTimem.toLocaleString("en-US", {
    timeZone: "Asia/Dubai",
  });
  const dateObj = new Date(dubaiTime);
  const hoursD = (dateObj.getHours() + 4).toString().padStart(2, "0");
  const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
  const time = `${hoursD}:${minutesD}`;
  const [isOff, setIsOff] = useState(0);
  const days = [
    { Mon: "Monday" },
    { Tue: "Tuesday" },
    { Wed: "Wednesday" },
    { Thu: "Thursday" },
    { Fri: "Friday" },
    { Sat: "Saturday" },
    { Sun: "Sunday" },
  ];
  //  start_time;
  //  end_time;

  const [start_time, setstart_time] = useState("00:00:00");
  const [start_time_show, setstart_time_show] = useState("12:00 AM");
  const [end_time, setend_time] = useState("23:00:00");
  const [end_time_show, setend_time_show] = useState("11:59 PM");
  // let start_time = "00:00:00";
  // let end_time = "23:00:00";
  // let isOff;
  let is_all_day;
  let nameDay;
  // let day_name;

  const timeref = useRef(null);

  const [showInput, setShowInput] = useState(false);

  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  const [title, setTitle] = useState("")
  useEffect(() => {
    setLoadign(true);
    const getData = async () => {
      axios
        .get(
          `/${room_uuid}/spa/${params.spa_uuid}/add-service/${params.spa_id}`,
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          const data = res.data.data;
          setService(data.service);
          setWorking_times(data?.spa?.working_times);
          setSpaID(data.spaId);
          setTherapists(data.service.therapists);
          setphone_number_required(data.phone_number_required);
          setSelectedOption(data.service?.extras[0]?.options[0].id);
          setDuration(data.service?.extras[0]?.options[0].name);
          setSelectedPrice(data.service?.extras[0]?.options[0].price);
          setDiscountPrice(data.service?.extras[0]?.options[0].discount_price);
          setTitle(data?.header_title)
          setPrice_expression(data.price_expression);

          const dayOfWeek = new Date(dateInput).toLocaleString("en-us", {
            weekday: "short",
          });
          // if (data?.spa?.working_times?.length > 0) {
          //   handleDate();
          // }

          if (data?.spa?.working_times?.length > 0) {
            nameDay = days?.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];
            const dayCur = data?.spa?.working_times?.filter((time) => {
              if (time?.working_day?.en_name == nameDay) {
                setDay_name(time?.working_day?.name);
                return time;
              }
            });

            setIsOff(dayCur[0]?.is_off);
            if (dayCur[0]?.is_off == 0) {
              is_all_day = dayCur[0]?.is_all_day;
              if (dayCur[0]?.is_all_day != 1) {
                setstart_time(dayCur[0]?.start_time);
                let hours_count =
                  dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

                const [hours_, minutes_, seconds_] = dayCur[0]?.start_time
                  ?.split(":")
                  .map(Number);

                let totalHours = Number(hours_) + Number(hours_count);



                setend_time(
                  `${String(totalHours).padStart(2, "0")}:${String(
                    minutes_
                  ).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`
                );

                setstart_time_show(dayCur[0]?.start_time_12)
                setend_time_show(dayCur[0]?.end_time_12)
                // timeref.current.max = end_time;
                // timeref.current.min = start_time;
              } else {
                setstart_time("00:00:00");
                setend_time("23:00:00");
                setstart_time_show("12:00 AM")
                setend_time_show("11:59 PM")
              }
              // timeref.current.max = end_time;
              // timeref.current.min = start_time;
            }
          }

          setLoadign(false);
          window.scrollTo({
            top: 0,
            left: 0,
          });
        });
    };

    getData();
  }, []);

  const handleDate = (e) => {
    console.log("new Date(dateInput).toLocaleString215115466.......", new Date(dateInput).toLocaleDateString());
    const currentDay = new Date().toLocaleDateString();
    console.log("currentDay.......", currentDay);
    console.log("new Date(dateInput).toLocaleString().......", currentDay == new Date(dateInput).toLocaleString());
    if (currentDay == new Date(dateInput).toLocaleDateString()) {

      const hour = new Date().getHours() + 4
      setTimeInput(`${hour}:00`);
      sethourShow(hour);
      setMinShow('00');
      // alert(timeInput)
    }
    const dayOfWeek = new Date(dateInput).toLocaleString("en-us", {
      weekday: "short",
    });
    nameDay = days.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];

    const dayCur = working_times?.filter((time) => {
      if (time.working_day.en_name == nameDay) {
        return time;
      }
    });

    console.log("dayCur[0]", dayCur[0]);
    console.log("nameDay", nameDay);
    setIsOff(dayCur[0]?.is_off)
    is_all_day = dayCur[0]?.is_all_day;
    if (dayCur[0]?.is_off == 0) {
      setstart_time(dayCur[0]?.start_time);
      let hours_count =
        dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

      const [hours_, minutes_, seconds_] = dayCur[0]?.start_time
        ?.split(":")
        .map(Number);

      let totalHours = Number(hours_) + Math.floor(Number(hours_count));



      setend_time(
        `${String(totalHours).padStart(2, "0")}:${String(
          minutes_
        ).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`
      );
      setstart_time_show(dayCur[0]?.start_time_12)
      setend_time_show(dayCur[0]?.end_time_12)
    } else {
      setstart_time("00:00:00")
      setend_time("23:00:00")
      setstart_time_show("12:00 AM")
      setend_time_show("11:59 PM")
    }

    setDateInput(dateInput);
  };

  useEffect(() => {
    const day_ = new Date(dateInput).getDate().toString().padStart(2, "0");
    const month = (new Date(dateInput).getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const year = new Date(dateInput).getFullYear().toString().padStart(2, "0");
    const formatDate = `${year}-${month}-${day_}`;
    console.log("dateInputdateInputdateInput", dateInput);
    if (working_times) {
      handleDate();
    }
    setDateInput(formatDate);
  }, [dateInput]);
  // useEffect(() => {
  //   const currentTime = new Date();

  //   // Format the time as HH:mm (hours and minutes)
  //   let hours = currentTime.getHours().toString().padStart(2, "0");

  //   const minutes = currentTime.getMinutes().toString().padStart(2, "0");

  //   const currentDate = new Date();
  //   // console.log("formattedTime", formattedTime);
  //   // Format the date as YYYY-MM-DD (required by input type="date")

  //   // const formattedDate = currentDate.toISOString().slice(0, 10);
  //   let day = currentDate.getDate().toString().padStart(2, "0");
  //   const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = currentDate.getFullYear().toString().padStart(2, "0");
  //   // const formatDate = `${year}-${month}-${day}`;
  //   // console.log("hours", hours);
  //   if (hours == "24") {
  //     hours = "01";
  //     // console.log("day", day);
  //     day = day + 1;
  //   }
  //   // const formattedTime = `${hours}:${minutes}`;
  //   // setDateInput(formatDate);

  //   // console.log("formatDate", formatDate);
  //   // setTimeInput(formattedTime);
  // }, []);


  const checkValPhone = () => {
    if (phone_number_required) {
      if (spaService.phone != "") {
        return true

      } else {

        return false
      }
    }
    else {
      return true
    }
  }
  const handleBook = async (e) => {
    e.preventDefault();
    console.log("isOff******", isOff);
    console.log("timeInput******", timeInput);
    console.log("end_time******", end_time);
    console.log("start_time******", start_time);
    console.log("days", Object?.values(days[new Date(dateInput).getDay() == 0 ? 6 : new Date(dateInput).getDay() - 1])[0]);


    if (isOff == 0) {
      if (timeInput > end_time) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",

          detail: `${t(
            "The booking time must be between"
          )} ${start_time_show} ${t("and")} ${end_time_show}`,
          life: 5000,
        });
      } else if (start_time > timeInput) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",

          detail: `${t(
            "The booking time must be between"
          )} ${start_time_show} ${t("and")} ${end_time_show}`,
          life: 5000,
        });
      } else {
        checkInputs();
        if (spaService.name != "" && !checkValEmail && checkValPhone()) {
          setBookLoadign(true);
          const booked = {
            name: spaService.name,
            email: spaService.email,
            phone: spaService.phone,
            date: dateInput,

            time: timeInput,
            // therapist_id: therapist.id,
            spa_id: spaID,
            service_id: id,
            duration: duration,
            options: [selectedOption],
            // company_name: company.company_name,
            // company_id: company.company_id,
            // lat: "25.276987",
            // lng: "55.296249",
          };
          axios
            .post(`/${room_uuid}/spa/book-services`, booked, {
              headers: {
                lang: lang,
              },
            })
            .then((res) => {
              setIsDisabled(false);

              setBookLoadign(false);
              console.log("res", res);
              if (res.data.status == false) {
                toast.current.show({
                  severity: "error",
                  summary: t("Error"),
                  detail: t(`${res.data.message}`),
                  life: 4000,
                });
              }
              if (res.data.status) {
                navigate(`/${room_uuid}/SpaOrderPlaced`, {
                  state: { metadata: res.data.data, theme: true },
                });
              }
            })
            .catch((err) => {
              setIsDisabled(false);
              setBookLoadign(false);
              console.log(err);
            });
        }
      }
      // if(start_time)
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `${t("We are off on")} ${Object?.values(days[new Date(dateInput).getDay() == 0 ? 6 : new Date(dateInput).getDay() - 1])[0]} ${t("at")} ${timeInput} ${t("please choose another time")}`,
        life: 5000,
      });
    }
  };

  console.log("start", start_time);
  console.log("end_time", end_time);
  console.log("timeInput", timeInput);

  console.log("service", service);

  const [discount_price, setDiscountPrice] = useState();
  return (
    <div className={theme ? "food pb-2" : ""}>
      {theme ? <Title title={title} /> : <HeaderInner headerName={title} />}
      <main className="bg-white back-white">
        <Toast ref={toast} position="bottom-center" />

        {loadign ? (
          <SpaLoader />
        ) : (
          <>
            <div className="add-post-container add-cart mt-2">
              <div className="add-cart-post new spa gray ">
                <div className="add-cart-post-inner">
                  {/* <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div> */}
                  <span
                    className="img-pnl navigate"
                  // onClick={() => {
                  //   handleShow();
                  //   setModalImage(Post1);
                  // }}
                  >
                    <img src={service ? service.image : Post} />
                  </span>
                  <div
                    className="cross-btn-red"
                    onClick={() =>
                      navigate(
                        theme
                          ? `/${room_uuid}/hotel-services/spa/${params.spa_uuid}`
                          : `/${room_uuid}/spa/${params.spa_uuid}`
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 23 23"
                    >
                      <path
                        id="Icon_ionic-ios-close-circle"
                        data-name="Icon ionic-ios-close-circle"
                        d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                        transform="translate(-3.375 -3.375)"
                        fill="#f11027"
                      ></path>{" "}
                    </svg>
                  </div>
                  <div
                    className="txt-pnl navigate"
                  // onClick={handleCartShow}
                  // onClick={() => navigate("/SpaAddService")}
                  >
                    <h2>{service ? service.name : "loading"}</h2>
                    <p></p>
                    <div
                      className="flex-div align-item-end px-2"
                      style={{
                        justifyContent: "end",
                      }}
                    >
                      <Link
                        to={
                          theme
                            ? `/${room_uuid}/hotel-services/spa/${params.spa_uuid}`
                            : `/${room_uuid}/spa/${params.spa_uuid}`
                        }
                        className="reg-btn service"
                      >
                        {t("Change")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="description-text">
              <p>{service?.description && service.description}</p>
            </div>
            <div className="duration-div spa_form">
              {
                service?.extras.map((item) => {
                  return (
                    <>

                      <p className="title start duration">{item.name}</p>
                      <div className="people_count">
                        <ul>
                          {
                            item.options.map(
                              ({ id, name, price, discount_price }) => {
                                return (
                                  <li
                                    key={id}
                                    className={id == selectedOption ? "active" : ""}
                                    onClick={() => {
                                      setSelectedOption(id);
                                      setDuration(name);
                                      setDiscountPrice(discount_price);
                                      setSelectedPrice(price);

                                      ReactGA.event({
                                        category: "Select option in SPA",
                                        action: "Click",
                                        value: `option: ${name}, price: ${discount_price != 0 ? discount_price : price
                                          }`,
                                      });
                                    }}
                                  >
                                    {name}
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </div>
                    </>


                  )
                })
              }
              <Row>
                <Col xs="6">
                  <p className="title"> {t("Order Total")}</p>
                </Col>
                <Col xs="6" className="d-flex justify-content-end">
                  <p className="title">
                    <span className="d-flex" style={{ minWidth: "125px", justifyContent: "end" }}>
                      {discount_price != 0 && (
                        <span className="spa_discount">
                          {currency} {selectedPrice}
                        </span>
                      )}
                      {`${currency} ${discount_price != 0 ? discount_price : selectedPrice
                        }`}
                    </span>
                  </p>
                </Col>
              </Row>

              <CalendarComponent date={dateInput} setDate={setDateInput} />

              <div className="time_res">
                <div className="time_box">
                  <p className="">{t("Booking Time")}</p>
                  <div className="div_time">
                    <span className="hour">{hourShow}</span>
                    <span className="doots">:</span>
                    <span className="hour">{minShow}</span>
                  </div>
                </div>
                <div className="time_box mr">
                  <label
                    onClick={() => {
                      if (!showInput) {
                        setShowInput(true);
                      }
                      setTimeout(() => {
                        if (tineRif.current) {
                          // tineRif.current.focus();
                          tineRif.current.click();
                        }

                      }, 0);
                    }}
                    // htmlFor="time-input"
                    className={`input_time_change ${showInput ? "notOpac" : ""
                      }`}
                  >
                    <span className="btn">{t("Change")}</span>
                    {showInput && (
                      <Form.Control
                        type="time"
                        className="hide_inp"
                        value={timeInput}
                        ref={tineRif}
                        // onSelect={() => setShowInput(false)}
                        onBlur={(e) => {
                          setShowInput(false)
                        }}
                        onChange={handleTime}
                      />
                    )}
                  </label>
                </div>
              </div>
            </div>

          </>
        )}
        <form>
          <div className="padd-div spa-inputs">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                // value={`Room number ${}`}
                value={`${t("Room number")} ${room_number}`}
                // placeholder={`Room number ${room_number}`}
                readonly
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder={`${t("Your Name")}`}
                name="name"
                value={spaService.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errName != "" && <span className="err_msg">{t(`${errName}`)}</span>}
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="emial"
                placeholder={`${t("Your Email (Optional)")}`}
                name="email"
                value={spaService.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errorEmail != "" && <span className="err_msg">{t(`${errorEmail}`)}</span>}
            {/* <Row>
              <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Control
                    type="date"
                    value={dateInput}
                    min={new Date().toISOString().slice(0, 10)}
                    onChange={handleDate}
                    defaultValue={dateInput}
                  />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Control
                    type="time"
                    value={timeInput}
                    ref={timeref}
                    min={start_time}
                    max={end_time}
                    onInvalid={handleInvalid}
                    required
                    onChange={handleTime}
                  />
                </Form.Group>
              </Col>
            </Row> */}

            <Form.Group className="mb-3 w-100">
              <PhoneInputComponent
                country={phoneFormat}
                value={spaService.phone}
                onChange={(phone) => {
                  setSpaService((prev) => ({ ...prev, phone }));
                }}
              />
            </Form.Group>
            {errPhone != "" && (
              <span className="err_msg" >
                {t(`${errPhone}`)}
              </span>
            )}
          </div>
          <div className="spacer-40 mt-3"></div>
          <div className="padd-div text-center ">
            <Button
              className="reg-btn order fw-normal"
              onClick={handleBook}
              disabled={bookLoadign}
            >
              {t("Book")}
            </Button>
          </div>
        </form>
        <div className="padd-div text-center">
          <h3 className="text-center">
            <TaxInclusive />
          </h3>
        </div>
        <div className="spacer-40 my-5"></div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default SpaAddService;
