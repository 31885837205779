import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const SearchSc = ({ placeholder, room_uuid, category, text }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location.pathname);

  return (
    <div
      className="Search-bar less mt-4"
      onClick={() => navigate(`/${room_uuid}/restaurants/search?${text}`)}
    >
      <input
        className="form-control"
        type="search"
        placeholder={t(placeholder)}
        disabled
      />
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>
  );
};

export default SearchSc;
