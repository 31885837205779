import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import Carosle from "../../Components/Carosle";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import MyComponent from "../../Components/MyComponent";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import BannerHome from "../../new-components/banner-home/BannerHome";
import { ThemeContext } from "../../context/ThemeContext";
function AdboutUs() {
  const location = useLocation();

  const rawPath = location.pathname;
  const { theme } = useContext(ThemeContext);
  const room_uuid = rawPath.split("/")[1];
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [headerImages, setHeaderImages] = useState([]);
  const [header_title, setheader_title] = useState();
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const About = async () => {
      // axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
      await axios
        .get(`/${room_uuid}/about-us`, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            setData(data);

            let headerImage = data.realvideo
              ? [{ image: data.realvideo }, ...data?.header_images]
              : data?.header_images;

            setHeaderImages(headerImage);
            console.log("data***", data);
            setLoading(false);
          }
        });
    };
    About();
  }, []);

  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={data?.header_title} />
      ) : (
        <HeaderInner headerName={data?.header_title} />
      )}
      <main className="home-main pad-top-0 back-white about-us order_page">
        {loading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerHome headerImages={headerImages} isFirst={true} />
            ) : (
              <Carosle
                className={"dot-bar less-margin small-dots"}
                headerImages={headerImages}
                isFirst={true}
              />
            )}
            <div className="info w-100 mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.438"
                height="17.438"
                viewBox="0 0 17.438 17.438"
              >
                <defs>
                  <style></style>
                </defs>
                <path
                  id="Icon_awesome-info-circle"
                  data-name="Icon awesome-info-circle"
                  class="cls-1info"
                  d="M9.281.563A8.719,8.719,0,1,0,18,9.281,8.72,8.72,0,0,0,9.281.563Zm0,3.867A1.477,1.477,0,1,1,7.8,5.906,1.477,1.477,0,0,1,9.281,4.43Zm1.969,8.93a.422.422,0,0,1-.422.422H7.734a.422.422,0,0,1-.422-.422v-.844a.422.422,0,0,1,.422-.422h.422V9.844H7.734a.422.422,0,0,1-.422-.422V8.578a.422.422,0,0,1,.422-.422h2.25a.422.422,0,0,1,.422.422v3.516h.422a.422.422,0,0,1,.422.422Z"
                  transform="translate(-0.563 -0.563)"
                ></path>
              </svg>
              <p>{t("Info")}</p>
            </div>
            <div class="desc">{data?.about_us}</div>
            {data?.extensions?.length > 0 && (
              <>
                <div className="info w-100 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g id="phone" transform="translate(0)">
                      <path
                        id="Path_339"
                        data-name="Path 339"
                        class="cls-1ex"
                        d="M16.764,14.136l-2.4,2.378A1.734,1.734,0,0,1,13.088,17a11.086,11.086,0,0,1-5.5-2.071,21.205,21.205,0,0,1-6.416-7A9.057,9.057,0,0,1,0,3.941a1.745,1.745,0,0,1,.487-1.3l2.4-2.4a.793.793,0,0,1,1.3.216L6.113,4.121A1.061,1.061,0,0,1,5.9,5.328l-.883.883a.434.434,0,0,0-.09.252,7.528,7.528,0,0,0,2.271,3.35c.906.831,1.88,1.958,3.145,2.224a.5.5,0,0,0,.46-.045l1.027-1.045a1.173,1.173,0,0,1,1.243-.18h.018l3.478,2.053a.837.837,0,0,1,.2,1.315Z"
                        transform="translate(0 0)"
                      ></path>
                    </g>
                  </svg>

                  <p>{t("Extensions")} </p>
                </div>
                {/* <p class=" sub-title">{data?.extensions}</p> */}
              </>
            )}

            <table>
              <tbody>
                {data?.extensions?.map((item) => {
                  return (
                    <tr class="detailes">
                      <td>{item.name} </td>
                      <td>{item?.pivot?.number}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="info w-100 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.061"
                height="19.684"
                viewBox="0 0 14.061 19.684"
              >
                <defs>
                  <style>
                    {/* .cls-1lo {
                          fill: #006390;
                        } */}
                  </style>
                </defs>
                <g id="location" transform="translate(-9 -3)">
                  <circle
                    id="Ellipse_122"
                    data-name="Ellipse 122"
                    class="cls-1lo"
                    cx="1.424"
                    cy="1.424"
                    r="1.424"
                    transform="translate(14.696 8.694)"
                  ></circle>
                  <path
                    id="Path_340"
                    data-name="Path 340"
                    class="cls-1lo"
                    d="M16.03,3A6.89,6.89,0,0,0,9,9.723c0,1.765.8,4.112,2.391,6.977a51.551,51.551,0,0,0,3.515,5.414,1.4,1.4,0,0,0,2.251,0A51.766,51.766,0,0,0,20.672,16.7c1.584-2.864,2.389-5.211,2.389-6.977A6.89,6.89,0,0,0,16.03,3Zm0,9.842a2.812,2.812,0,1,1,2.812-2.812A2.812,2.812,0,0,1,16.03,12.842Z"
                    transform="translate(0 0)"
                  ></path>
                </g>
              </svg>
              <p>{t("Location")} </p>
            </div>

            <MyComponent lat={data.lat} lng={data.long} />
          </>
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default AdboutUs;
