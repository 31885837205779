import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Maintenance = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/maintenance/companies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowMaintenance = (uuid, lang) => {
  return useQuery(`show_maintenance-${lang}`, () => Maintenance(uuid, lang));
};

const Maintenances = async (uuid, e_uuid, lang) => {
  return await APIURL.get(`/${uuid}/maintenance/categories/${e_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowMaintenances = (uuid, e_uuid, lang) => {
  return useQuery(`maintenances-${lang}-${e_uuid}`, () =>
    Maintenances(uuid, e_uuid, lang)
  );
};
