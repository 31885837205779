import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import Car11 from "../assets/images/Car-Rental/cardisplay.png";
import PhoneInput from "react-phone-input-2";
import SpaLoader from "./Loaders/SpaLoader";
import axios from "../axios";
import { useTranslation } from "react-i18next";
import { phoneFormat } from "../constants/api";
import PhoneInputComponent from "./PhoneInputComponent";

function CarBookModalSearch({ car, room_uuid }) {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  const [count, setCount] = useState(1);
  const [timeInput, setTimeInput] = useState("");
  const [dateInput, setDateInput] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [activeColor, setActiveColor] = useState(car.colors[0].id);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // console.log("car", activeColor);

  const [nameErr, setNameErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [sDateErr, setSDateErr] = useState("");
  const [edateErr, setEdateErr] = useState("");
  const checkEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);
  const checkInputs = () => {
    if (name == "") {
      setNameErr("The name field is required.");
    } else {
      setNameErr("");
    }
    if (phone == "") {
      setPhoneErr("The name phone is required.");
    } else {
      setPhoneErr("");
    }
    if (dateInput == "") {
      setSDateErr("Start Date is a required field");
    } else {
      setSDateErr("");
    }
    if (timeInput == "") {
      setEdateErr("End Date is a required field");
    } else {
      setEdateErr("");
    }
    if (checkEmail) {
      setEmailErr("The email must be a valid email address.");
    } else {
      setEmailErr("");
    }
  };

  const data = {
    car_id: car.id,
    car_color: activeColor,
    payment_frequency: "DAILY",
    name: name,
    email: email,
    phone: phone,
    start_date: dateInput,
    end_date: timeInput,
  };

  const handleDecrease = () => {
    setCount((prev) => {
      if (prev <= 0) {
        return 0;
      }
      return parseInt(prev) - 1;
    });
  };
  const handleIncrease = () => {
    setCount((prev) => parseInt(prev) + 1);
  };
  const handleTime = (e) => {
    setTimeInput(e.target.value);
  };
  const handleDate = (e) => {
    setDateInput(e.target.value);
  };
  const [enableClass, setEnableClass] = useState(true);
  setTimeout(() => {
    setEnableClass(false);
  }, 1000);
  const handelOrder = (e) => {
    checkInputs();
    if (phone != "" && dateInput != "" && timeInput != "" && !checkEmail) {
      e.preventDefault();

      setLoading(true);

      axios
        .post(`/${room_uuid}/cars/bookCar`, data, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            navigate(`/${room_uuid}/CarRentalOrderPlaced`, {
              state: {
                metadata: {
                  res: res.data.data,
                  data: {
                    img: car.image,
                    price: car.price,
                    name: car.car_model_name,
                    year: car.year,
                  },
                },
              },
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  };

  return (
    <>
      {enableClass ? (
        <SpaLoader />
      ) : (
        <>
          <div className="modal-item">
            <div className="car-img-container  w-100">
              <div></div>
              <img src={car.image} alt="Post" />{" "}
            </div>

            <div className="txt-pnl">
              <Link to="/RestaurantOrderPayment">
                <h2>
                  {car.car_model_name} {car.year}
                </h2>
              </Link>
              <div className="flex-div">{/* <h3> {car.price}</h3> */}</div>
            </div>
          </div>

          <div className="full-heading flex grey-bg m-0">
            <h3>{t("Details")} </h3>
          </div>
          <div className="check-list-container">
            <Form>
              <ul className="check-list v3">
                <li>
                  <p> {t("Year")}</p>
                  <p>{car.year}</p>
                </li>
                <li>
                  <p>{t("Payment Frequency ")} </p>
                  <p>{car.payment_frequency}</p>
                </li>
              </ul>
            </Form>
          </div>
          <div className="full-heading d-flex justify-content-start grey-bg m-0">
            <h3>{t("Color")} </h3>
            <p className="text-danger mb-0 ml-2">{t("Required")} </p>
          </div>
          <div className="padd-div">
            <ul className="flex-div color-btn-list">
              {car.colors &&
                car.colors.map((item) => {
                  const { color } = item;
                  return (
                    <li
                      id={item.id}
                      className={
                        activeColor === item.id
                          ? "color-btn active"
                          : "color-btn"
                      }
                    >
                      <Button
                        style={{
                          backgroundColor: color,
                          borderColor: color == "White" ? `Gray` : color,
                        }}
                        onClick={() => setActiveColor(item.id)}
                      ></Button>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="full-heading flex grey-bg m-0">
            <h3> {t("Your Information")} </h3>
          </div>
          <div className="full-heading m-0">
            <p style={{ marginBottom: 5 }}>
              {t("Enter your information and get your car")}
            </p>
          </div>

          <div className="car-input-container">
            <Row>
              <Col xs="12">
                <Form.Group className="mb-3 p" controlId="formBasicname">
                  <Form.Control
                    type="email"
                    className="pl-5"
                    placeholder="Your Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                {nameErr != "" && <span className="err_msg">{t(`${nameErr}`)}</span>}
              </Col>
              <Col xs="12">
                <Form.Group className="mb-3 p" controlId="formBasicname">
                  <Form.Control
                    type="text"
                    className="pl-5"
                    placeholder="Your Email (Optional)"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                {emailErr != "" && <span className="err_msg">{t(`${emailErr}`)}</span>}
              </Col>
            </Row>
            <Form.Group className="mb-3 w-100">
              <PhoneInputComponent
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </Form.Group>
            {phoneErr != "" && <span className="err_msg">{t(`${phoneErr}`)}</span>}
            <div className="bk-date">
              <Form.Group
                className="mb-3 row align-items-center justify-content-start"
                controlId="formBasicname"
              >
                <p className="col-3 m-0 f-12 ">{t("Start Date")}</p>
                <Form.Control
                  type="date"
                  value={dateInput}
                  onChange={handleDate}
                  min={new Date().toISOString().split("T")[0]}
                  placeholder="Enter Your Email"
                  className="col-8 ml-2  dt-input"
                />
              </Form.Group>
              {sDateErr != "" && <span className="err_msg">{t(`${sDateErr}`)}</span>}
            </div>
            <div className="bk-time mb-4">
              <Form.Group
                className="mb-3 row align-items-center justify-content-start "
                controlId="formBasicname"
              >
                <p className="col-3 m-0 f-12 no-break">{t("End Date")}</p>
                <Form.Control
                  type="date"
                  value={timeInput}
                  min={
                    dateInput != ""
                      ? new Date(dateInput).toISOString().split("T")[0]
                      : new Date().toISOString().split("T")[0]
                  }
                  onChange={handleTime}
                  placeholder="Enter Your Email"
                  className="col-8 ml-2  dt-input"
                />
              </Form.Group>
              {edateErr != "" && <span className="err_msg">{t(`${edateErr}`)}</span>}
            </div>
          </div>

          <div className="check-list-container">
            <div className="full-div padd-div text-center">
              {/* <Link className="reg-btn big " to="/CarRentalOrderPlaced">
                Book Now
              </Link> */}
              <Button
                className="reg-btn big"
                onClick={handelOrder}
                disabled={loading}
              >
                {t("Book Now")}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default CarBookModalSearch;
