import React from "react";
import { Zoom } from "react-reveal";
import { Link } from "react-router-dom";

const RCategories = ({ category, room_uuid, url, theme }) => {
  return (
    <li key={category.id}>
      <Zoom>
        <Link
          to={
            theme
              ? `/${room_uuid}/food-and-beverages/in-room-dining/${category.restaurant_uuid}/category/${category.id}`
              : `/${room_uuid}/${url}/${category.restaurant_uuid}/${category.id}`
          }
        >
          <div
            className="img-pnl"
            style={{ backgroundImage: `url(${category.image})` }}
          ></div>
          <img src={category.image} alt="" />
          <div></div>
          <div className="bg-layer"></div>
          <p>{category.name}</p>
        </Link>
      </Zoom>
    </li>
  );
};

export default RCategories;
