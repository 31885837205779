import React from 'react';
import Post from '../assets/images/post.png';
function GeneralPost() {
    return (
        <>
            <div className='general-post'>
                <div className='closed-tag'></div>
                <div className='img-pnl'>
                    <img src={Post} alt="Post"/>
                </div>
                <div className='txt-pnl'>
                    <h2>Bella Restaurant & Lounge</h2>
                    <p>Italian Food, Burgers</p>
                </div>
            </div>
        </>
    );
}
export default GeneralPost; 