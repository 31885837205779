const cartSlice = (set) => ({
  cart: [],
  laundryCart: [],
  cartCount: 0,

  setCart: (input) => set((state) => ({ cart: input })),
  setCartCount: (input) => set((state) => ({ cartCount: input })),
  setLaundryCart: (input) => set((state) => ({ laundryCart: input })),
});

export default cartSlice;
