import React from "react";

const NotActiveRoom = () => {
  return (
    <div className="not_active">
      <h4>Sorry!</h4>
      <p>This room is not active</p>
    </div>
  );
};

export default NotActiveRoom;
