import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";

import FlowersComponent from "../../Components/FlowersComponent";
import Search from "../../Components/Search";
import NewFooter from "../../NewFooter";
import Title from "../../new-components/title/Title";

function FlowersCategories() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const params = useParams();
  const theme = location.state?.theme;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  // <HeaderInner headerName="Flowers" />
  return (
    <div className={theme ? "food pb-0" : ""}>
      {theme ? (
        <Title title={"Flowers"} />
      ) : (
        <HeaderInner headerName={"Flowers"} />
      )}
      <main className="home-main bg-white back-white  pad-top-0">
        {
          <>
            <Search
              placeholder="Search for Categories"
              room_uuid={room_uuid}
              category="Flowers"
              className="mt-1"
            />

            {
              <FlowersComponent
                room_uuid={room_uuid}
                fl_uuid={params.flower_uuid}
                fl_id={params.flower_id}
                theme={theme}
              />
            }
          </>
        }
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default FlowersCategories;
