import React from "react";
import AddCartPost from "../AddCartPost";
import { useNavigate } from "react-router-dom";

const RItems = ({ item, room_uuid, lang, theme }) => {
  console.log("langser", lang);
  const navigate = useNavigate();
  return (
    <div
      className={`add-post-container add-cart`}
      // onClick={() =>
      //   navigate(
      //     `/${room_uuid}/RestaurantBooking/RestaurantSubMenu/${item.restaurant_uuid}/${item.category_id}`
      //   )
      // }
    >
      <AddCartPost
        item={item}
        isOffCategory={item.is_category_off}
        restaurant_is_closed={false}
        restaurantId={item.restaurant_id}
        isbooking={!item.can_order && !item.is_category_off}
        theme={theme}
      />
    </div>
  );
};

export default RItems;
