import React, { useEffect, useState } from "react";
import HeaderInner from "../../Components/HeaderInner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";
import CheckCheckout from "./CheckCheckout";

const LaundryCallback = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  var url = new URLSearchParams(location.search);
  var statusValue = url.get("status");
  var message = url.get("message");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Get the value of the 'status' parameter

  return (
    <>
      <HeaderInner headerName={"Checkout"} />
      <main className="bg-white back-white v2">
        {
          <CheckCheckout
            status={statusValue}
            message={message}
            urlAPI={"laundry/order/order-receipt"}
            navigatUrl={"LaundryOrderPlaced"}
          />
        }
      </main>
    </>
  );
};

export default LaundryCallback;
