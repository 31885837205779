import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import TranslationEN from "./locale/en.json";
import TranslationAR from "./locale/ar.json";
import TranslationFR from "./locale/fr.json";
import TranslationRU from "./locale/ru.json";
import TranslationHI from "./locale/hi.json";
import TranslationCH from "./locale/ch.json";
import TranslationDE from "./locale/de.json";
import TranslationKO from "./locale/ko.json";

const langu = localStorage.getItem("lang") || "EN";

const resources = {
  EN: {
    translation: TranslationEN,
  },
  AR: {
    translation: TranslationAR,
  },
  FR: {
    translation: TranslationFR,
  },
  RU: {
    translation: TranslationRU,
  },
  HI: {
    translation: TranslationHI,
  },
  CH: {
    translation: TranslationCH,
  },
  DE: {
    translation: TranslationDE,
  },
  KO: {
    translation: TranslationKO,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: langu,
    fallbackLng: langu,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
