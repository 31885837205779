import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Luxurios = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/luxurios/companies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowLuxurios = (uuid, lang) => {
  return useQuery(`show_Luxurios-${lang}`, () => Luxurios(uuid, lang));
};

const Luxurioss = async (uuid, e_uuid, lang) => {
  return await APIURL.get(`/${uuid}/luxurios/categories/${e_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowLuxurioss = (uuid, e_uuid, lang) => {
  return useQuery(`Luxurioss-${lang}-${e_uuid}`, () =>
    Luxurioss(uuid, e_uuid, lang)
  );
};
