import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const HH_HouseKeeping = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/hh-house-keeping-companies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHH_HouseKeeping = (uuid, lang) => {
  return useQuery(`show_HH_HouseKeeping-${lang}`, () =>
    HH_HouseKeeping(uuid, lang)
  );
};

const HH_HouseKeepings = async (uuid, e_uuid, lang) => {
  return await APIURL.get(`/${uuid}/hh-housekeeping/services/${e_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHH_HouseKeepings = (uuid, e_uuid, lang) => {
  return useQuery(`HH_HouseKeepings-${lang}-${e_uuid}`, () =>
    HH_HouseKeepings(uuid, e_uuid, lang)
  );
};
const PharmacySubMenu = async (uuid, ph_uuid, id, lang) => {
  return await APIURL.get(`/${uuid}/pharmacy/${ph_uuid}?cat=${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};

export const ShowPharmacySubMenu = (uuid, ph_uuid, id, lang) => {
  return useQuery(`pharmacy_sub_menu-${lang}-${id}`, () =>
    PharmacySubMenu(uuid, ph_uuid, id, lang)
  );
};
