import React from 'react';
import { Link } from "react-router-dom";
import Post from '../assets/images/slide-3.png';
function TextPost() {
    return (
        <>
            <div className='text-post'>
                <div className='img-pnl'>
                    <img src={Post} alt="Post"/>
                </div>
                <div className='txt-pnl'>
                    <p>Flow your Senses, make your own me-time and escape the hustle of the city! Forget about the time and let your senses flow with the rhythm of our rituals that we designed to refresh, revive and nourish both body and soul</p>
                </div>
                <div className='Add-new-pnl'>
                    <p>Add the spa service you want</p>
                    <Link className='reg-btn big' to="/"><i className='fa fa-plus-square'></i> Add a New service</Link>
                </div>
            </div>
        </>
    );
}
export default TextPost; 