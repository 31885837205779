// UserContext.js
import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const ThemeContext = createContext();

// Create a provider component
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(false);

    // Load theme from localStorage when the component mounts
    useEffect(() => {
        const storedTheme = localStorage.getItem('new_design');
        if (storedTheme == true || storedTheme == "true") {
            setTheme(true);
        } else {
            setTheme(false);
        }
    }, []);

    // Save theme to localStorage whenever it changes
    // useEffect(() => {
    //     if (theme) {
    //         localStorage.setItem('new_design', theme);
    //     }
    // }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
