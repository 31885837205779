import { t } from "i18next";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import { useLocation } from "react-router-dom";
import { API_URL } from "../constants/api";

const TaxInclusive = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];

  const [tax_inclusive, settax_inclusive] = useState(1);
  const [currency, setcurrency] = useState("AED");
  const [business_name, setBusiness_name] = useState("");
  const [business_type, setBusiness_type] = useState("");
  const [show_prices, setshow_prices] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      axios.get(`/${room_uuid}/qr-code?source=footer`).then((res) => {
        const data = res.data.data;
        setLoading(false);

        settax_inclusive(data?.tax_inclusive);
        setcurrency(data?.currency);
        setshow_prices(data?.show_prices);
        setBusiness_name(data?.business_name);
        setBusiness_type(data?.businessType);
      });
    };
    getData();
  }, []);

  let charge = "10%";
  let vat = "5%";
  let fee = "7%";
  if (business_name.includes("Sunset Jeddah")) {
    vat = "15%";
  }
  if (currency === "SAR") {
    vat = "15%";
  }

  return (
    <>
      {!loading ? (
        show_prices ? (
          <>
            {API_URL?.includes("sgp") ?
              t("Prices are inclusive of government taxes and hotel fees.")
              :
              <>
                {t("Prices are in ")}
                {t(`${currency}`)}
                {t(" and are")}
                {tax_inclusive == 1 ? t(" inclusive ") : t(" exclusive ")}
                {/* {t(`of 10% service charges, 5% VAT & 7% Municipality fee.`)} */}
                {currency == "SAR" ? t(
                  `of ${vat} VAT`
                ) : t(
                  business_type != "Holiday Home" ?
                    `of ${charge} service charges, ${vat} VAT & ${fee} Municipality fee.`
                    :
                    `of ${charge} service charges, ${vat} VAT`
                )}
              </>
            }
          </>
        ) : null
      ) : null}
    </>
  );
};

export default TaxInclusive;
