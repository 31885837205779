import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const HealthCare = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/health_care_and_wellness/companies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHealthCare = (uuid, lang) => {
  return useQuery(`show_HealthCare-${lang}`, () => HealthCare(uuid, lang));
};

const HealthCares = async (uuid, e_uuid, lang) => {
  return await APIURL.get(
    `/${uuid}/health_care_and_wellness/categories/${e_uuid}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};
export const ShowHealthCares = (uuid, e_uuid, lang) => {
  return useQuery(`HealthCares-${lang}-${e_uuid}`, () =>
    HealthCares(uuid, e_uuid, lang)
  );
};
