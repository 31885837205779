import React from "react";
import HeaderInner from "../../Components/HeaderInner";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
import Post1 from "../../assets/images/Spa/Post-3.png";
import Gif from "../../assets/images/add-to-cart.gif";

function SATOrderPlaced() {
  return (
    <>
      <HeaderInner />
      <main className="bg-white back-white pad-top-0 spa-order-placed">
        <div className="img-pnl">
          <img src={Gif} alt="Place Order" />
        </div>
        <h2 className="order-placed">Your Booking is Confirmed</h2>
        <Clientinfo className={"spa"} />

        <div className="full-heading grey-bg flex">
          <h2 className="blck co-header">Order Details</h2>
        </div>
        <div className="full-div padd-div">
          <div className="booking-order-detail">
            <div className="img-pnl">
              <img src={Post1} alt="Spa" />
            </div>
            <div className="txt-pnl">
              <p>REVIVING JOURNEY</p>
              <h3>AED 545.00</h3>
            </div>
          </div>
        </div>
        <div className="spacer-40"></div>
        <div className="full-div padd-div text-center">
          <a className="reg-btn large" href="/HomeMenu/qr-code">
            {" "}
            Back to Home
          </a>
        </div>
      </main>
    </>
  );
}
export default SATOrderPlaced;
