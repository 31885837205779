import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaCarosle from "../../Components/SpaCarosle";
import Post1 from "../../assets/images/Spa/Post-1.png";

import ReactGA from "react-ga4";

import Carosle from "../../Components/Carosle";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { ShowSPA } from "../../API/SPAAPI";
import { useTranslation } from "react-i18next";

function Spa() {
  const [count, setCount] = useState(1);
  const [modalImage, setModalImage] = useState(Post1);
  const [showCart, setShowCart] = useState(false);
  const [show, setShow] = useState(false);
  // const [spaServices, setSpaServices] = useState([]);
  // const [spaId, setSpaId] = useState();
  const currency = localStorage.getItem("currency");

  const location = useLocation();
  const navigate = useNavigate();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const spa_uuid = rawPath.split("/spa/")[1];
  // console.log("spa_uuid", spa_uuid);
  const lang = localStorage.getItem("lang");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCartClose = () => setShowCart(false);
  const { t, i18n } = useTranslation();

  const handleCartShow = () => setShowCart(true);
  const handleIncrease = () => {
    setCount((prev) => {
      if (prev <= 0) {
        return 0;
      }
      return prev - 1;
    });
  };
  const handleDecrease = () => {
    setCount((prev) => prev + 1);
  };

  let spa;
  let images;
  let bgColor;
  let price;
  let color;
  let text;
  let spaId;
  let spaServices;
  let discount_percentage;
  let working_times;
  const { data, isLoading, error } = ShowSPA(room_uuid, spa_uuid, lang);

  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        spa = data.data.data.spa;
        images = spa?.header_images;
        color = data.data.data.color;
        bgColor = data.data.data.header_color;
        text = data.data.data.header_title;
        spaServices = spa.services;
        discount_percentage = spa.discount_percentage;

        // working_times = spa.working_times;
        spaId = spa.id;
        // console.log("spa", spa);
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios
  //       .get(`/${room_uuid}/spa/fbfca775-2609-4061-8a8e-9d232ed9c3f3`)
  //       .then(async (res) => {
  //         const data = res.data.data.spa;
  //         setHeaderImages(data.header_images);
  //         console.log("MY", data);
  //         setSpaId(data.id);
  //         setSpaServices(data.services);
  //         setLoading(false)
  //         // setLaundaryServices(data.services);
  //         // setLaundaryItems(data);
  //       });
  //   };
  //   getData();
  // }, []);
  return (
    <>
      <HeaderInner headerName={text} />
      <main className="home-main bg-white back-white pad-top-0 pb-30">
        {isLoading ? (
          <SpaLoader />
        ) : (
          <>
            <Carosle className={"dot-bar"} headerImages={images} />
            {spaServices &&
              spaServices.map(({ image, name, price, id, extras }) => {
                return (
                  <div className="add-post-container add-cart">
                    <div className="add-cart-post new spa ">
                      <div className="">
                        <Link
                          to={`/${room_uuid}/spa/${spa_uuid}/service/${id}`}
                          state={id}
                          onClick={() => {
                            ReactGA.event({
                              category: "Open SPA card",
                              action: "Click",
                              value: `SPA: ${name}`,
                            });
                          }}
                          className="add-cart-post-inner"
                        >
                          <span
                            className="img-pnl navigate"
                            onClick={() => {
                              handleShow();
                              setModalImage(image);
                            }}
                          >
                            <img src={image} alt="Post" />
                          </span>
                          <div
                            className="txt-pnl navigate"
                            onClick={handleCartShow}
                          // onClick={() => navigate("/SpaAddService")}
                          >
                            <h2>{name}</h2>
                            <p></p>
                            <div className="flex-div align-item-center">
                              <h3>
                                {extras[0]?.options.length > 1 ? (
                                  <span style={{ fontSize: "12px" }}>
                                    {t("Price upon selection")}
                                  </span>
                                ) : discount_percentage ? (
                                  <span className="spa_price">
                                    <span className="discount_spa">{`${currency} ${Number(
                                      price
                                    )}`}</span>
                                    <span>{`${currency} ${Number(price) -
                                      (Number(price) *
                                        Number(discount_percentage)) /
                                      100
                                      }`}</span>
                                  </span>
                                ) : (
                                  <span>{`${currency} ${Number(price)}`}</span>
                                )}
                              </h3>
                              <span className="reg-btn service">
                                {t("Add Service")}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        )}

        {/*    <div className="add-post-container add-cart">
          <div className="add-cart-post new spa">
            <div className="add-cart-post-inner">
              <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div>

              <img src={Post3} alt="Post" />
             
            </div>
          </div>
        </div>
        <div className="add-post-container add-cart">
          <div className="add-cart-post new spa">
            <div className="add-cart-post-inner">
              <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div>
              <img src={Post4} alt="Post" />
              <div className="txt-pnl">
                <h2>BACK, NECK AND SHOULDER MASSAGE</h2>
                <p></p>
                <div className="flex-div align-item-center">
                  <Link to={"/SpaAddService"} className="reg-btn service">
                    Add Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-post-container add-cart">
          <div className="add-cart-post new spa">
            <div className="add-cart-post-inner">
              <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div>
              <img src={Post5} alt="Post" />
              <div className="txt-pnl">
                <h2>SWEDISH MASSAGE</h2>
                <p></p>
                <div className="flex-div align-item-center">
                  <Link to={"/SpaAddService"} className="reg-btn service">
                    Add Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-post-container add-cart">
          <div className="add-cart-post new spa">
            <div className="add-cart-post-inner">
              <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div>
              <img src={Post6} alt="Post" />
              <div className="txt-pnl">
                <h2>AROMATHERAPY MASSAGE</h2>
                <p></p>
                <div className="flex-div align-item-center">
                  <Link to={"/SpaAddService"} className="reg-btn service">
                    Add Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-post-container add-cart">
          <div className="add-cart-post new spa">
            <div className="add-cart-post-inner">
              <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div>
              <img src={Post7} alt="Post" />
              <div className="txt-pnl">
                <h2>ULTIMATE REJUVENATION EXPERIENCE</h2>
                <p></p>
                <div className="flex-div align-item-center">
                  <Link to={"/SpaAddService"} className="reg-btn service">
                    Add Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-post-container add-cart">
          <div className="add-cart-post new spa">
            <div className="add-cart-post-inner">
              <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div>
              <img src={Post8} alt="Post" />
              <div className="txt-pnl">
                <h2>ENERGIZE AND NOURISH</h2>
                <p></p>
                <div className="flex-div align-item-center">
                  <Link to={"/SpaAddService"} className="reg-btn service">
                    Add Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-post-container add-cart">
          <div className="add-cart-post new spa">
            <div className="add-cart-post-inner">
              <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div>
              <img src={Post9} alt="Post" />
              <div className="txt-pnl">
                <h2>SKIN ESSENCE ORGANICS DEEP CLEANSE FACIAL</h2>
                <p></p>
                <div className="flex-div align-item-center">
                  <Link to={"/SpaAddService"} className="reg-btn service">
                    Add Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </main>
      {/* <Modal
        show={show}
        size="sm"
        onHide={handleClose}
        centered
        className="modal-xs"
      >
        <Button className="close-btn img" onClick={handleClose}>
          <i className="fa fa-times-circle-o"></i>
        </Button>
        <Modal.Body style={{ padding: "7px" }}>
          <div className="modal-img-container">
            <Image src={modalImage} alt="Post" fluid />
          </div>
        </Modal.Body>
      </Modal> */}
      {/*  <Modal
        show={show}
        // style={{ maxWidth: "430px" }}
        className="custom-width img"
        onHide={handleClose}
      >
        <Button className="close-btn img" onClick={handleClose}>
          <div className="close-btn-wrap"></div>
          <AiOutlineCloseCircle />
        </Button>
        <Modal.Body style={{ padding: "7px" }}>
          <div className="modal-img-container">
            <Image src={modalImage} alt="Post" />
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <Modal show={showCart} size="md" onHide={handleCartClose} centered>
        <Button className="close-btn img" onClick={handleCartClose}>
          <i className="fa fa-times-circle-o"></i>
        </Button>
        <Modal.Body style={{ padding: "25px" }}>
          <div className="add-post-container add-cart">
            <div className="add-cart-post new spa">
              <div className="add-cart-post-inner">
                <div
                  className={
                    enableClass ? "animated-background bg-skimmer" : "d-none"
                  }
                ></div>
                <span className="img-pnl">
                  <img src={Post1} alt="Post" />
                </span>
                <div className="txt-pnl">
                  <h2>SKIN ESSENCE ORGANICS EXPRESS FACIAL</h2>
                  <p></p>
                  <div className="flex-div">
                    <div
                      className="count-order-amount "
                      style={{ display: "flex " }}
                    >
                      <Button className="small" onClick={handleIncrease}>
                        <i className="fa fa-minus"></i>
                      </Button>
                      <p>{count}</p>
                      <Button className=" small" onClick={handleDecrease}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button className="reg-btn small w-100">Add To Cart</Button>
          </div>
        </Modal.Body>
      </Modal> */}
      {/*  <Modal show={showCart} className="custom-width" onHide={handleCartClose}>
        <Button className="close-btn" onClick={handleCartClose}>
          <AiOutlineCloseCircle />
        </Button>
        <Modal.Body>
          <div className="modal-img-container">
            <Image src={Post1} alt="Post" />
          </div>
          <div className="modal-text-container sub-menu">
            <h2>SKIN ESSENCE ORGANICS EXPRESS FACIAL</h2>
            <p></p>
            <div className="price-container">
              <div className="count-order-amount">
                <Button className="small" onClick={handleIncrease}>
                  <i className="fa fa-minus"></i>
                </Button>
                <p>{count}</p>
                <Button className=" small" onClick={handleDecrease}>
                  <i className="fa fa-plus"></i>
                </Button>
              </div>
            </div>
            <div className="full-div mt-4">
              <Link
                className="reg-btn big w-100 flex-div"
                to="/RestaurantOrderPayment"
              >
                <div>
                  <i className="fa fa-plus-square"></i> Add to Cart
                </div>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
export default Spa;
