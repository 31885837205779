import React from "react";
import { useLocation } from "react-router-dom";
import order from "../assets/images/order.png";
import { useTranslation } from "react-i18next";
import Gif from "../assets/images/add-to-cart.gif";
function Textheader() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  return (
    <>
      <header className="text-header m-0">
        <div className="checkout-header">
          <div className="img-pnl">
            <img src={Gif} alt="Place Order" />
          </div>
          <h2>
            {location.pathname === "/RestaurantOrderPlaced"
              ? t("Your order is placed")
              : ""}
            {location.pathname === "/SpaOrderPlaced"
              ? t("Your booking is confirmed")
              : ""}
            {location.pathname === "/EntertainmentOrderPlaced"
              ? t("Your booking is confirmed")
              : ""}
            {location.pathname === "/CarRentalOrderPlaced"
              ? t("Your car has been booked")
              : ""}
            {location.pathname === "/FlowerOrderPlaced"
              ? t("Your Order Placed")
              : ""}
            {location.pathname === "/PharmacyOrderPlaced"
              ? t("Your Order Placed")
              : ""}
            {location.pathname === "/LaundryOrderPlaced"
              ? t("Your Order Placed")
              : ""}
          </h2>
        </div>
      </header>
    </>
  );
}
export default Textheader;
