import React from "react";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
  isBefore,
} from "date-fns";
import "./Calendar.css";
import { t } from "i18next";

const Calendar = ({ date, setDate }) => {
  const [currentMonth, setCurrentMonth] = React.useState(date);

  const today = new Date();

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";


    console.log("currentMonth", format(currentMonth, dateFormat).split(" "));


    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div
            className={`icon ${isSameMonth(currentMonth, today) ? "disabled" : ""
              }`}
            onClick={() =>
              !isSameMonth(currentMonth, today) &&
              setCurrentMonth(subMonths(currentMonth, 1))
            }
          >
            <i className="fas fa-angle-left"></i>
          </div>
        </div>
        <div className="col col-center">
          <span>{t(`${format(currentMonth, dateFormat).split(" ")[0]}`)}<br />{`${format(currentMonth, dateFormat).split(" ")[1]}`}</span>
        </div>
        <div
          className="col col-end"
          onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
        >
          <div className="icon">
            <i className="fas fa-angle-right"></i>
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    const dateFormat = "EEEE";
    const startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {t(`${format(addDays(startDate, i), dateFormat).slice(0, 3)}`)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isDisabled =
          !isSameMonth(day, monthStart) ||
          (isBefore(day, today, { unit: "day" }) && !isSameDay(day, today));

        days.push(
          <div
            className={`col cell ${isDisabled ? "disabled" : isSameDay(day, date) ? "selected" : ""
              } ${isSameDay(day, today) ? "current" : ""}`}
            key={day}
            onClick={() => !isDisabled && setDate(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
