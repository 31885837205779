import React from 'react'
import { Link } from 'react-router-dom'

const HomeLoader = () => {
    return (
        <div className='spacer-10 full-div ' style={{ height: "100vh" }}>



            <div className="img-pnl  position-relative mb-4">
                <img
                    src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                    className=""
                    style={{ width: "200px" }}
                    alt="In-Room Dining"
                />
                <div
                    className={
                        "animated-background bg-skimmer"
                    }
                    style={{

                        left: "-5px",
                        top: "-5px",
                        bottom: "-5px",
                        right: "-5px",
                    }}
                ></div>
            </div>
            <div className="txt-pnl position-relative">
                <div
                    className={
                        "animated-background bg-skimmer"
                    }
                ></div>
                <p className="">tt</p>
            </div>

            <ul className='menu-list'>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        id="trans-anime"
                        className={"trans"}
                        to=""
                    >
                        <div className="img-pnl  position-relative">
                            <img
                                src="https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/9.png"
                                className=""
                                style={{ width: "45px" }}
                                alt="In-Room Dining"
                            />
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                                style={{
                                    borderRadius: "50%",
                                    left: "-5px",
                                    top: "-5px",
                                    bottom: "-5px",
                                    right: "-5px",
                                }}
                            ></div>
                        </div>
                        <div className="txt-pnl position-relative">
                            <div
                                className={
                                    "animated-background bg-skimmer"
                                }
                            ></div>
                            <p className="">tt</p>
                        </div>
                    </Link>
                </li>




            </ul>
        </div >
    )
}

export default HomeLoader