import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import bgimg from "../src/assets/images/slide-1.png";
import Dine1 from "../src/assets/images/In-Room-Dining/Post-1.png";
import Dine2 from "../src/assets/images/In-Room-Dining/Post-2.png";
import Dine3 from "../src/assets/images/In-Room-Dining/Post-3.png";
import Dine4 from "../src/assets/images/In-Room-Dining/Post-4.png";
import Dine5 from "../src/assets/images/In-Room-Dining/Post-5.png";
import Dine6 from "../src/assets/images/In-Room-Dining/Post-6.png";
import Dine7 from "../src/assets/images/In-Room-Dining/Post-7.png";
import Dine8 from "../src/assets/images/In-Room-Dining/Post-8.png";
import Payment1 from "../src/assets/images/Payments/Payment-3.png";
import Payment2 from "../src/assets/images/Payments/Payment-1.png";
import Payment3 from "../src/assets/images/Payments/Payment-2.png";
import Payment4 from "../src/assets/images/Payments/Payment-4.png";
import order from "../src/assets/images/order.png";
import Carosle from "./Components/Carosle";
import GeneralPost from "./Components/GeneralPost";
import Searchbar from "./Components/Searchbar";
import MenuList from "./Components/MenuList";
import TextPost from "./Components/TextPost";
import AddCartPost from "./Components/AddCartPost";
function Extra() {
  return (
    <>
      <main>
        <div className="full-div padd-div">
          <ul className="your-order-list">
            <li>
              <p>
                <i className="fa fa-credit-card"></i> Room Number :
              </p>{" "}
              <span>205</span>
            </li>
            <li>
              <p>
                <i className="fa fa-calendar-o"></i> Your Order Number :
              </p>{" "}
              <span>219</span>
            </li>
            <li>
              <p>
                <i className="fa fa-bookmark-o"></i> Date & Time :
              </p>{" "}
              <span>10:50 AM</span>
            </li>
          </ul>
        </div>
        <ul className="payemtn-type-list">
          <li>
            <Link>
              <img src={Payment1} alt="Payment" />
              <p>cash on delivery</p>
            </Link>
          </li>
          <li>
            <Link>
              <img src={Payment2} alt="Payment" />
              <p>card on delivery</p>
            </Link>
          </li>
          <li>
            <Link>
              <img src={Payment3} alt="Payment" />
              <p>pay online</p>
            </Link>
          </li>
          <li>
            <Link>
              <img src={Payment4} alt="Payment" />
              <p>upon checkout</p>
            </Link>
          </li>
        </ul>
        <div className="full-heading flex grey-bg m-0">
          <h3>Yogurt</h3>
        </div>
        <div className="check-list-container">
          <Form>
            {["radio"].map((type) => (
              <ul key={`inline-${type}`} className="check-list">
                <li>
                  <p>Orange</p>
                  <Form.Check
                    reverse
                    label=""
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </li>
                <li>
                  <p>Peanapple</p>
                  <Form.Check
                    reverse
                    label="(+ AED 8)"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </li>
                <li>
                  <p>Apple</p>
                  <Form.Check
                    reverse
                    label="(+ AED 8)"
                    name="group1"
                    type={type}
                    id={`inline-${type}-3`}
                  />
                </li>
              </ul>
            ))}
          </Form>
        </div>
        <div className="full-div padd-div">
          <a className="reg-btn big w-100 flex-div" href="/">
            <div>
              <i className="fa fa-plus-square"></i> Add to Cart
            </div>
            <div>AED 110.00</div>
          </a>
        </div>
        <div className="full-heading flex grey-bg m-0">
          <h3>Coffee</h3>
        </div>
        <div className="check-list-container">
          <Form>
            {["checkbox"].map((type) => (
              <ul key={`inline-${type}`} className="check-list">
                <li>
                  <p>Freshly Brewed</p>
                  <Form.Check
                    reverse
                    label=""
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </li>
                <li>
                  <p>Regular</p>
                  <Form.Check
                    reverse
                    label="(+ AED 8)"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </li>
                <li>
                  <p>Decaffeinated</p>
                  <Form.Check
                    reverse
                    label="(+ AED 8)"
                    name="group1"
                    type={type}
                    id={`inline-${type}-3`}
                  />
                </li>
              </ul>
            ))}
          </Form>
        </div>

        <div className="add-post-container full-post">
          <AddCartPost />
        </div>
        <div className="add-post-container add-cart">
          <AddCartPost />
        </div>
        <div className="add-post-container">
          <AddCartPost />
        </div>
        <header className="text-header">
          <div>
            <div className="img-pnl">
              <img src={order} alt="Place Order" />
            </div>
            <h2>Your order is placed</h2>
          </div>
        </header>
        <header className="img-header">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${bgimg})` }}
          ></div>
        </header>
        <ul className="display-list one-item">
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine1})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Breakfast</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine2})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Lunch</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine3})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Dinner</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine4})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Dessert</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine5})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Meat</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine6})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Soups</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine7})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Drinks</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine8})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Salad</p>
            </Link>
          </li>
        </ul>
        <ul className="display-list two-items">
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine1})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Breakfast</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine2})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Lunch</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine3})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Dinner</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine4})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Dessert</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine5})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Meat</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine6})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Soups</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine7})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Drinks</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine8})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Salad</p>
            </Link>
          </li>
        </ul>
        <ul className="display-list three-items">
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine1})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Breakfast</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine2})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Lunch</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine3})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Dinner</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine4})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Dessert</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine5})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Meat</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine6})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Soups</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine7})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Drinks</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Dine8})` }}
              ></div>
              <div className="bg-layer"></div>
              <p>Salad</p>
            </Link>
          </li>
        </ul>
        <ul className="inline-list">
          <li>
            <Link to="/">In-Room Dining</Link>
          </li>
          <li className="active">
            <Link to="/">Pharmacy</Link>
          </li>
          <li>
            <Link to="/">Flowers</Link>
          </li>
          <li>
            <Link to="/">Car Rental</Link>
          </li>
          <li>
            <Link to="/">Entertainment</Link>
          </li>
        </ul>
        <Searchbar />
        <TextPost />
        <Carosle />
        <MenuList />

        <div className="full-heading flex">
          <h2 className="blck">Order Total</h2>
          <h2 className="blck">AED 545.00</h2>
        </div>
        <div className="text-center">
          <h3>All Prices are inclusive of VAT</h3>
        </div>
        <div className="padd-div">
          <Link to="/" className="reg-anchor">
            reg-anchor
          </Link>
        </div>
        <div className="text-center">
          <Link className="reg-btn" to="/">
            Book
          </Link>
          <Link className="reg-btn big" to="/">
            Back To Home Page
          </Link>
        </div>

        <div className="full-heading">
          <h2>Recommended Restaurants</h2>
        </div>
        <ul className="inline-post flex">
          <li>
            <GeneralPost />
          </li>
          <li>
            <GeneralPost />
          </li>
        </ul>
        <ul className="inline-post">
          <li>
            <GeneralPost />
          </li>
          <li>
            <GeneralPost />
          </li>
        </ul>
        <div className="general-post-container">
          <GeneralPost />
        </div>
        <div className="general-post-container closed">
          <GeneralPost />
        </div>
      </main>
    </>
  );
}
export default Extra;
