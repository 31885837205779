import React, { useEffect } from "react";
import Title from "../../new-components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "../food-and-beverages/FoodAndBeverages.scss";
import ServiceImage from "../../assets/new_imgs/ServiceImage.jpg";

const ExternalServices = () => {
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const metadata = location.state?.metadata;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  console.log("metadata", metadata);
  return (
    <div className={`food  ${lang === "AR" && "arabic"}`}>
      <Title title={"External Services"} />

      <div className="box_food image_services">

        {metadata?.map((service) => {
          if (service.id === 25) {
            return (
              <Link to={`/${params.room_uuid}/external-services/things-to-do`}>
                <img src={ServiceImage} alt="" className="service_img" />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 10) {
            return (
              <Link to={`/${params.room_uuid}/external-services/pharmacy`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/pharmacy.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 11) {
            return (
              <Link to={`/${params.room_uuid}/external-services/flowers`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/flowers.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 9) {
            return (
              <Link to={`/${params.room_uuid}/external-services/supermarket`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/supermarket.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 16) {
            return (
              <Link to={`/${params.room_uuid}/external-services/entertainment`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/entertainment.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 13) {
            return (
              <Link to={`/${params.room_uuid}/external-services/cars`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/rental.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />{" "}
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 24) {
            return (
              <Link to={`/${params.room_uuid}/luxurios/companies`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Luxurious.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />{" "}
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 6) {
            return (
              <Link to={`/${params.room_uuid}/external-services/taxi`}>
                <img
                  src={"https://d3l5wxnahfuscp.cloudfront.net/static/taxi.png"}
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 21) {
            return (
              <Link to={`/${params.room_uuid}/external-services/hh-taxi`}>
                <img
                  src={"https://d3l5wxnahfuscp.cloudfront.net/static/taxi.png"}
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />

                <h6 className="sub_title">
                  {lang === "AR" ? service.service_name : service.display_name}
                </h6>
              </Link>
            );
          }
          if (service.id === 14) {
            return (
              <Link
                to={`/${params.room_uuid}/external-services/hh-housekeeping`}
              >
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Housekeeping.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
        })}
      </div>
      <NewFooter />
    </div>
  );
};

export default ExternalServices;
