import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

export const CarsAPI = async ({
  room_uuid,
  make,
  model,
  year,
  pageParam,
  sort_direction,
  lang,
}) => {
  return await APIURL.get(
    `/${room_uuid}/cars?make=${make}&model=${model}&year=${year}&sort_direction=${sort_direction}&page=${pageParam}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};
