import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Supermarket = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/supermarkets`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowSupermarkets = (uuid, lang) => {
  return useQuery(`supermarkets-${lang}`, () => Supermarket(uuid, lang));
};

const SupermarketCategories = async (uuid, ph_uuid, lang) => {
  return await APIURL.get(`/${uuid}/supermarket/categories/${ph_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowSupermarketCategories = (uuid, ph_uuid, lang) => {
  return useQuery(`supermarket-categories-${lang}-${ph_uuid}`, () =>
    SupermarketCategories(uuid, ph_uuid, lang)
  );
};
export const SupermarketSubMenuAPI = async ({
  room_uuid,
  pharmacy_uuid,
  selected,
  pageParam,
  lang,
  all,
}) => {
  return await APIURL.get(
    `/${room_uuid}/supermarket/${pharmacy_uuid}?cat=${selected}&page=${pageParam}${
      all == "all" ? "&all=1" : ""
    }`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};

// export const ShowPharmacySubMenu = (uuid, ph_uuid, id, page) => {
//     return useQuery(`pharmacy_sub_menu-${id}`, () => PharmacySubMenu(uuid, ph_uuid, id, page));
// }

const SupermarketSubCatAPI = async (room_uuid, pharmacy_uuid, cat_id, lang) => {
  return await APIURL.get(
    `/${room_uuid}/supermarket/categories/${pharmacy_uuid}?cat=${cat_id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};

export const ShowSupermarketSubCatAPI = (uuid, pharmacy_uuid, cat_id, lang) => {
  return useQuery(`SupermarketSubCatAPI-${pharmacy_uuid}-${cat_id}`, () =>
    SupermarketSubCatAPI(uuid, pharmacy_uuid, cat_id, lang)
  );
};

const SupermarketItem = async (uuid, f_id, lang) => {
  return await APIURL.get(`/${uuid}/supermarket/get-item-supermarket/${f_id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowSupermarketItem = (uuid, f_id, lang) => {
  return useQuery(`SupermarketItem-${lang}-${f_id}`, () =>
    SupermarketItem(uuid, f_id, lang)
  );
};
