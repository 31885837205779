import React from "react";
import { Accordion } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import Pharmacy from "../../assets/images/Pharmacy/Medicine.png";
import Payment1 from "../../assets/images/Payments/Payment-3.png";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
function PharmacyOrderPlaced() {
  return (
    <>
      <HeaderInner />
      <main className="bg-white pad-top-0">
        <Textheader />
        <Clientinfo />

        <div className="full-heading grey-bg flex">
          <h2 className="blck">Order Details</h2>
        </div>
        <div className="padd-div order-accordian">
          <Accordion orders>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p>
                  <b>× 2</b> Brufen Rapid 400Mg
                </p>
                <p>AED 32.25</p>
                <i className="fa fa-angle-down"></i>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex">
                  <div className="img-pnl">
                    <img src={Pharmacy} alt="Pharmacy" />
                  </div>
                  <div className="text-pnl">
                    <div className="full-div">
                      <p>
                        - 10 Purple Orchids
                        <br></br>- Wrapped in Pink Paper
                        <br></br>- Tied with Pink Ribbon
                      </p>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="padd-div">
          <Accordion defaultActiveKey="0" ordertotal>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p>order total</p>
                <p>AED 32.25</p>
                <i className="fa fa-angle-down"></i>
              </Accordion.Header>
              <Accordion.Body>
                <div className="flex-div">
                  <p>Sub Total</p>
                  <p>AED 32.25.00</p>
                </div>
                <div className="flex-div">
                  <p>discount</p>
                  <p>AED 02.75</p>
                </div>
                <div className="flex-div">
                  <h6>order total</h6>
                  <h6>AED 30.25</h6>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="full-heading grey-bg flex">
          <h2 className="blck">Payment Method</h2>
          <h2 className="grey">
            <img src={Payment1} alt="cash" />
            cash on delivery
          </h2>
        </div>
        <div className="full-div padd-div text-center">
          <a className="reg-btn big" href="/Restaurants">
            {" "}
            Back To Home Page
          </a>
        </div>
      </main>
    </>
  );
}
export default PharmacyOrderPlaced;
