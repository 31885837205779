import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainments } from "../../API/EntertainmentAPI";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import { ShowGeneralInformationAPI } from "../../API/GeneralInformationAPI";
import ModalGeneralInformation from "./ModalGeneralInformation";
import { t } from "i18next";
import { ThemeContext } from "../../context/ThemeContext";

function GeneralInformation() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const euuid = rawPath.split("categories/")[1];
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState();
  const [textMod, setTextMod] = useState();
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const lang = localStorage.getItem("lang");
  const [desMod, setDesMod] = useState();
  const handleShow = (id, text, des) => {
    // console.log("id", id, "color:green");
    setCategory(id);
    setTextMod(text);
    setDesMod(des);
    setShow(true);
  };

  let flowers;
  let bgColor;
  let color;
  let text;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowGeneralInformationAPI(room_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        console.log("flowers", flowers);

        entertainmentCategories = flowers.HospitalGeneralInfoCategories;
        color = flowers.color;
        bgColor = flowers.header_color;
        text = flowers.title;

        console.log("ShowEntertainmentss", flowers);
      }
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className={theme ? "food pb-1" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white back-white  pad-top-0 taxi_book ">        <div className={theme ? "box_food" : ""}>
        {isLoading ? (
          <SpaLoader />

        ) :
          <ul
            className={
              "menu-list jsc"
            }
          >
            {entertainmentCategories?.map((service, idx) => {
              return (
                <li
                  key={idx}

                >
                  <Zoom>
                    {
                      service?.id == 2 ?
                        <a
                          id="trans-anime"
                          className={`trans closed ${service?.is_closed && "services-option"
                            } `}
                          href={service.link}
                          target="_blank"
                        >
                          <div className="img-pnl  position-relative " >
                            <div className="closed-tag"></div>
                            <img
                              src={service.image}
                              className=""
                              style={{ maxWidth: "45px", maxHeight: "45px" }}
                              alt="In-Room Dining"
                            />
                            <div

                              style={{
                                borderRadius: "50%",
                                left: "-5px",
                                top: "-5px",
                                bottom: "-5px",
                                right: "-5px",
                              }}
                            ></div>
                          </div>
                          <div className="txt-pnl position-relative">
                            <div

                            ></div>

                            <p className="" style={{ paddingInline: "0px" }}>
                              {t(`${service.name}`)}
                            </p>
                          </div>
                        </a>
                        :
                        service?.id == 1 ?
                          <Link
                            id="trans-anime"
                            className={`trans closed ${service?.is_closed && "services-option"
                              } `}
                            state={{ metadata: service.hospital_links, description: service.description }}

                            to={`/${room_uuid}/hospital-general-info/site-map-links?title=${service.name}`}
                          >
                            <div className="img-pnl  position-relative " >
                              <div className="closed-tag"></div>
                              <img
                                src={service.image}
                                className=""
                                style={{ maxWidth: "45px", maxHeight: "45px" }}
                                alt="In-Room Dining"
                              />
                              <div

                                style={{
                                  left: "-5px",
                                  top: "-5px",
                                  bottom: "-5px",
                                  right: "-5px",
                                }}
                              ></div>
                            </div>
                            <div className="txt-pnl position-relative">
                              <div

                              ></div>

                              <p className="" style={{ paddingInline: "0px" }}>
                                {t(`${service.name}`)}
                              </p>
                            </div>
                          </Link>
                          :
                          <Link
                            id="trans-anime"
                            className={`trans closed ${service?.is_closed && "services-option"
                              } `}
                            state={{ metadata: service.description }}

                            to={service.is_book_form == 0 ? `/${room_uuid}/hospital-general-info/item?url=${service.link}&title=${service.name}&item_id=${service?.id}` : ""}
                            onClick={() => service.is_book_form == 1 ? handleShow(service.id, service.name, service.description) : null}
                          >
                            <div className="img-pnl  position-relative " >
                              <div className="closed-tag"></div>
                              <img
                                src={service.image}
                                className=""
                                style={{ maxWidth: "45px", maxHeight: "45px" }}
                                alt="In-Room Dining"
                              />
                              <div

                                style={{
                                  left: "-5px",
                                  top: "-5px",
                                  bottom: "-5px",
                                  right: "-5px",
                                }}
                              ></div>
                            </div>
                            <div className="txt-pnl position-relative">
                              <div

                              ></div>

                              <p className="" style={{ paddingInline: "0px" }}>
                                {t(`${service.name}`)}
                              </p>
                            </div>
                          </Link>
                    }

                  </Zoom>
                </li>
              );
            })}
          </ul>

        }
      </div>
      </main>
      {theme && <NewFooter />}
      <ModalGeneralInformation
        show={show}
        setShow={setShow}
        category={category}
        room_uuid={room_uuid}
        text={textMod}
        desMod={desMod}
        theme={theme}
      />
    </div>
  );
}
export default GeneralInformation;
