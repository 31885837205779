import React, { useContext, useEffect, useRef, useState } from "react";
import Iframe from "react-iframe";
import Title from "../../new-components/title/Title";
import HeaderInner from "../../Components/HeaderInner";
import NewFooter from "../../NewFooter";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { ThemeContext } from "../../context/ThemeContext";




function HospitalOutpatientInfoItem() {
  const [urlParam, setUrlParam] = useState("");
  const [title, setTitle] = useState("");
  const location = useLocation();
  const metadata = location.state?.metadata;
  useEffect(() => {
    const queryParams = new URLSearchParams(encodeURI(window.location.search));
    const url = queryParams.get("url");
    const title_ = queryParams.get("title");

    // Check if title_ is not null or undefined
    if (title_) {
      // Safe to replace the "%20" characters with spaces
      const new_title = title_.replace(/%20/g, " "); // You can use .replace here for a single replacement.
      setTitle(new_title);
      console.log("title_", new_title);
    } else {
      console.log("title_ is null or undefined");
    }

    setUrlParam(url);
  }, []);


  console.log("urlParam", urlParam);
  const parseHTMLToText = (html) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const { theme } = useContext(ThemeContext);

  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  ;
  // }, []);
  const [loading, setLoading] = useState(false);

  const handleLoad = () => {
    setLoading(false);
  };
  console.log("titletitletitle", title);

  // <HeaderInner headerName={"Taxi booking"} />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={title} />
      ) : (
        <HeaderInner headerName={title} />
      )}
      <main className="home-main bg-white back-white book-table pad-top-0 taxi_book thing_to">
        {metadata &&

          <div className="div-des">
            <p>{t(`${parseHTMLToText(metadata)}`)}</p>
          </div>
        }
        {loading && (
          <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <div className="loader"></div>

          </div>
        )}


        <Iframe
          url={urlParam}
          // url="https://www.wikipedia.org/"
          width="100%"
          // height="80vh"
          id="excelIframe"
          display="block"
          onLoad={handleLoad}

        />
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HospitalOutpatientInfoItem;
