import React, { useContext, useRef, useState } from "react";
import { Button, Modal, Dropdown, Form } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useEffect } from "react";
import { ShowValet } from "../../API/Concierge";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import ReactGA from "react-ga4";
import ValetImg from "../../assets/images/Valet.jpg";
import TaxInclusive from "../../Components/TaxInclusive.";
import "../../new-pages/hotel-services/HotelServices.scss";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import { ThemeContext } from "../../context/ThemeContext";

function Valet() {
  // **************************************

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [photoInputValue, setPhotoInputValue] = useState("");
  const [open, setOpen] = useState(false);

  console.log("photoInputValue", photoInputValue);
  const startCamera = async () => {
    setPhotoInputValue("");
    setOpen(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
        },
        audio: false,
      });
      ReactGA.event({
        category: "Start Camera",
        action: "Click",
        value: ``,
      });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const takePhoto = () => {
    setOpen(false);
    ReactGA.event({
      category: "Take photo",
      action: "Click",
      value: ``,
    });
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageDataUrl = canvas.toDataURL("image/jpeg");

      console.log(imageDataUrl);

      setPhotoInputValue(imageDataUrl);
    }
  };

  const switchCamera = () => {
    // You need to implement the logic for switching the camera
    // This could involve stopping the current stream and starting a new one with a different facing mode
    // For simplicity, I've left it as a placeholder function
    console.log("Switching camera");
  };

  // **************************************

  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;
  // const theme = location.search;
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const room_uuid = rawPath.split("/")[1];
  const [valet_time, setValet_time] = useState("5 Minuets");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [photo, setPhoto] = useState();
  const [note, setNote] = useState();
  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("lang");
  let valet;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowValet(room_uuid, lang);
  if (!isLoading) {
    let newData = data.data.data;
    valet = newData;
    color = newData?.color;
    bgColor = newData?.header_color;
    text = newData?.title;

    console.log("ShowValet", newData);
  }
  const [errorEmail, setErrorEmail] = useState("");

  const checkValEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);
  // console.log("checkemail", !/\S+@\S+\.\S+/.test(spaService.email));
  const checkInputs = () => {
    if (email.length > 0 && !/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }
  };
  const sendData = {
    type_id: "valet",
    email,
    phone,
    valet_time,
    note,
    photo: photoInputValue,
  };

  const handelBook = () => {
    checkInputs();
    if (!checkValEmail) {
      setIsDisabled(true);
      ReactGA.event({
        category: "Book service concierge ",
        action: "Click",
        value: `concierge type: valet`,
      });
      axios
        .post(`/${room_uuid}/concierge/book-service`, sendData, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          if (res.data.status == false) {
            toast.current.show({
              severity: "error",
              summary: t("Error"),
              detail: t(`${res.data.message}`),
              life: 4000,
            });
          }
          // console.log("res", res);
          if (res.data.status) {
            navigate(`/${room_uuid}/HKOrderPlaced`, {
              state: {
                metadata: {
                  res: res.data.data,
                },
              },
            });
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    }
  };
  const has_extra_services = localStorage.getItem("has_extra_services");

  return (
    <div className={theme ? "food pb-0" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white back-white book-table pad-top-0 book_ mb-0">
        {isLoading ? (
          <SpaLoader />
        ) : (
          <>
            <div className="con-order-img">
              <img src={ValetImg} alt="" />
            </div>
            <p className="bk-title mt-3 mb-2">{t(`Please Scan Your Ticket`)}</p>
            <div>
              <div className="take_img " onClick={startCamera}>
                <div className="video_img">
                  <video id="video" ref={videoRef} autoPlay playsInline></video>
                  {!open && (
                    <div className="camera_icon">
                      <i class="fas fa-camera"></i>
                      <p>{t("Click to open the camera")}</p>
                    </div>
                  )}
                </div>
                <canvas
                  id="canvas"
                  ref={canvasRef}
                  className={`${open ? "d-none" : ""}`}
                // style={{ width: "0px" }}
                ></canvas>
              </div>
              {/* <button
                id="start-camera"
                className={`${open ? "d-none" : ""}`}
                onClick={startCamera}
              >
                {t("Open Camera")}
              </button> */}
              <button
                id="start-camera"
                className={`${!open ? "d-none" : ""}`}
                onClick={takePhoto}
              >
                {t("Take a photo")}
              </button>

              <input
                id="photo-input"
                type="hidden"
                value={photoInputValue}
                readOnly
              />

              {photoInputValue != "" && (
                <button id="start-camera" onClick={startCamera}>
                  {t("Open Camera")}
                </button>
              )}
              {/* <button className="backCamera d-none" onClick={switchCamera}>
                Switch Camera
              </button> */}
            </div>
            <div className="val_peiople">
              <p className="ready pl-2">{t("Ready in")}</p>
              <div className="people_count valet all_width">
                <ul>
                  <li
                    className={valet_time == "5 Minuets" ? "active" : ""}
                    onClick={() => setValet_time("5 Minuets")}
                  >
                    {t("5 Min")}
                  </li>
                  <li
                    className={valet_time == "10 Minuets" ? "active" : ""}
                    onClick={() => setValet_time("10 Minuets")}
                  >
                    {t("10 Min")}
                  </li>
                  <li
                    className={valet_time == "15 Minuets" ? "active" : ""}
                    onClick={() => setValet_time("15 Minuets")}
                  >
                    {t("15 Min")}
                  </li>
                  <li
                    className={valet_time == "20 Minuets" ? "active" : ""}
                    onClick={() => setValet_time("20 Minuets")}
                  >
                    {t("20 Min")}
                  </li>
                </ul>
              </div>
            </div>
            <div className="px-2">
              {/* <div class="note">
                <p>{t("Time")} </p>
                <select
                  class="time-select"
                  name="valet_time"
                  required=""
                  onChange={(e) => setValet_time(e.target.value)}
                >
                  <option value="">{t("Select Time")} </option>
                  <option value="5 Minuets">{t("5 Minuets")} </option>
                  <option value="10 Minuets">{t("10 Minuets")}</option>
                  <option value="15 Minuets">{t("15 Minuets")}</option>
                  <option value="20 Minuets">{t("20 Minuets")}</option>
                </select>
              </div> */}

              <div className="con-email">
                <Form.Group
                  className="mb-3 align-items-center dark-placeholder justify-content-start w-100"
                  controlId="formBasicname"
                >
                  <Form.Control
                    type="email"
                    placeholder={t("Your Email (Optional)")}
                    className="w-100 "
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                {errorEmail != "" && (
                  <span className="err_msg">{errorEmail}</span>
                )}
              </div>
              <div className="con-phone">
                <Form.Group className="mb-3 w-100 ">
                  <PhoneInputComponent
                    className="ml-4"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-3 dark-placeholder i-f-small w-100">
                {/* <Form.Label>{t("Add note")} </Form.Label> */}
                <Form.Control
                  as="textarea"
                  placeholder={t("Your Notes")}
                  name="note"
                  onChange={(e) => setNote(e.target.value)}
                  style={{ height: "106px" }}
                />
              </Form.Group>
            </div>
            <div className="padd-div text-center mt-5">
              <Button
                className="reg-btn book lg"
                onClick={handelBook}
                disabled={isDisabled}
              >
                {t("Submit")}
              </Button>
            </div>
            {has_extra_services == "false" && (
              <div className="padd-div text-center"></div>
            )}
            <div className="spacer-40 my-5"></div>
          </>
        )}
      </main>
      {location.search && <NewFooter />}
    </div>
  );
}
export default Valet;
