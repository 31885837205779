import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";

import PharmacyLoader from "../../Components/Loaders/PharmacyLoader";
import Fade from "react-reveal/Fade";
import { ShowFlowers } from "../../API/FlowersAPI";
import FlowersComponent from "../../Components/FlowersComponent";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { useTranslation } from "react-i18next";

function Flowers() {
  const [flowerCategories, setFlowerCategories] = useState();
  const [title, setTitle] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);
  const lang = localStorage.getItem("lang");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  let florist = { id: "", uuid: "" };
  let flowers;
  let allFlowers;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowFlowers(room_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status == false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        if (flowers.florists.length > 0) {
          florist.id = flowers?.florists[0]?.id;
          florist.uuid = flowers?.florists[0]?.uuid;
          color = flowers.color;
          allFlowers = flowers.florists;
          bgColor = flowers.header_color;
          text = flowers.title;
        }
      }
      // console.log("flowers", flowers);
      if (allFlowers?.length == 1) {
        navigate(
          `/${room_uuid}/flower/category/${allFlowers[0].uuid}/id/${allFlowers[0].id}`,
          { replace: true }
        );
      }
      // console.log("ShowFlowers", flowers);
    }
    // console.log("flo_uuid", parmacy.uuid);
  }

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios.get(`/${room_uuid}/florists`).then((res) => {
  //       const data = res.data.data;
  //       console.log(data);
  //       setFlorist({
  //         id: data.florists[0].id,
  //         uuid: data.florists[0].uuid,
  //       });
  //       setTitle(data?.title)
  //       axios
  //         .get(`/${room_uuid}/florists/categories/${data.florists[0].uuid}`)
  //         .then((res) => {
  //           const data = res.data.data;
  //           setFlowerCategories(data.categories);

  //           setLoading(false)
  //           console.log(data);
  //         });
  //     });
  //   };
  //   getData();
  // }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <HeaderInner headerName={text} bgColor={bgColor} color={color} />
      <main className="home-main bg-white  pad-top-0 flowers">
        {isLoading ? (
          <SpaLoader />
        ) : allFlowers?.length > 1 ? (
          allFlowers?.map((flower, idx) => {
            return (
              <div key={idx}>
                <Zoom>
                  <div
                    className="con-img-container v3"
                    onClick={() =>
                      navigate(
                        `/${room_uuid}/flower/category/${flower.uuid}/id/${flower.id}`
                      )
                    }
                  >
                    <div className="con-img">
                      <img src={flower.image} alt="" />
                      <p></p>
                    </div>
                  </div>
                  <div className="con-text-container">
                    <p>{flower.name}</p>
                    <hr className="mb-0 dark" />
                  </div>
                </Zoom>
              </div>
            );
          })
        ) : null}
      </main>
    </>
  );
}
export default Flowers;
