import React, { useEffect, useState } from "react";
import HeaderInner from "../../Components/HeaderInner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";

const CheckCheckout = ({ status, message, urlAPI, navigatUrl }) => {
  console.log("status", status);
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  var url = new URLSearchParams(location.search);
  var statusValue = url.get("status");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // if (status == "false") {
  //   navigate(`/${room_uuid}/Checkout`, {
  //     state: { metadata: "message" },
  //   });
  // }

  useEffect(() => {
    if (status == "true") {
      const About = async () => {
        setLoading(true);
        // axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
        axios.get(`/${room_uuid}/${urlAPI}`).then((res) => {
          const data = res.data.data;
          setData(data);
          console.log("data***", data);
          navigate(`/${room_uuid}/${navigatUrl}`, {
            state: { metadata: res.data.data },
          });
          setLoading(false);
        });
      };
      About();
    } else {
      navigate(`/${room_uuid}/Checkout`, {
        state: { metadata: message },
      });
    }
  }, []);
  return <></>;
};

export default CheckCheckout;
