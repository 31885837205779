import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaCarosle from "../../Components/SpaCarosle";
import axios from "../../axios";
import Green from "../../assets/images/icons/green-leaf.png";
import { Button, Modal } from "react-bootstrap";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import "../../new-pages/hotel-services/HotelServices.scss";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { Accordion, AccordionTab } from "primereact/accordion";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { ThemeContext } from "../../context/ThemeContext";

function SiteMapLinks() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const [title, setTitle] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const metadata = location.state?.metadata;
  const description = location.state?.description;

  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const { t, i18n } = useTranslation();
  // const theme = rawPath.includes("hotel-services");
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  // console.log("theme", theme);
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const title_ = queryParams.get("title");
    setTitle(title_);
  }, []);

  // console.log("hotel-services", service);


  console.log("description", location.state);

  return (
    <div className={theme ? "food pb-0" : ""}>
      {theme ? <Title title={title} /> : <HeaderInner headerName={title} />}
      <main className="home-main bg-white back-white pad-top-0 house">
        {description &&
          <div className="pl-3">

            <div className="div-des"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
        }

        <>

          <div className="housekeeping-container">
            <div className="service-container">
              {metadata &&
                metadata.map((prop) => {
                  const {
                    id,
                    name,
                    link
                  } = prop;

                  return (
                    <>
                      <Button
                        className={
                          "reg-btn outline-btn d-flex justify-content-center "
                        }
                        onClick={() => navigate(`/${room_uuid}/hospital-general-info/site-map-links/item?url=${link}&title=${name}`)}


                      >
                        <span>{name}</span>


                      </Button>

                    </>
                  );
                })}
            </div>


          </div>
        </>
      </main>

      {theme && <NewFooter />}
    </div>
  );
}
export default SiteMapLinks;
