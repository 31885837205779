import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import PaymentOptionList from "../../Components/PaymentOptionList";
import { Form, Button } from "react-bootstrap";
import Post from "../../assets/images/food.png";
import PhoneInput from "react-phone-input-2";
import { useCartStore } from "../../store/useStore";
import CartItem from "../../Components/CartItem";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
function RestaurantOrderPayment() {
  const [enableClass, setEnableClass] = useState(true);
  const [count, setCount] = useState(1);
  const [drop, setDrop] = useState(false);
  const [count2, setCount2] = useState(1);
  const [phone, setPhone] = useState();

  const { cart, setCart } = useCartStore((state) => ({
    cart: state.cart,
    setCart: state.setCart,
  }));

  const toggleDrop = () => {
    setDrop((prev) => (prev === true ? false : true));
  };

  setTimeout(() => {
    setEnableClass(false);
  }, 3000);
  const handleIncrease = (i) => {
    if (i === 2) {
      setCount2((prev) => {
        if (prev <= 0) {
          return 0;
        }
        return prev - 1;
      });
    } else {
      setCount((prev) => {
        if (prev <= 0) {
          return 0;
        }
        return prev - 1;
      });
    }
  };
  const handleDecrease = (i) => {
    if (i === 2) {
      setCount2((prev) => prev + 1);
    } else {
      setCount((prev) => prev + 1);
    }
  };
  // const item = {
  //   name: "Lorem Ipsum",
  //   img: "https://tdhbucket.s3.me-central-1.amazonaws.com/restaurant_items/item1.png",
  //   price: "66.4",
  //   extra: "- Waikiki",
  // };
  return (
    <>
      <HeaderInner />
      <main className="bg-white back-white v2">
        {cart.map((item) => {
          return (
            /*  <div className="add-post-container v2 mt-4">
              <div className="add-cart-post payment v2">
                <div className="add-cart-post-inner">
                  <div
                    className={
                      enableClass ? "animated-background bg-skimmer" : ""
                    }
                  ></div>
                  <div className="cross-btn-red">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 23 23"
                    >
                      <path
                        id="Icon_ionic-ios-close-circle"
                        data-name="Icon ionic-ios-close-circle"
                        d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                        transform="translate(-3.375 -3.375)"
                        fill="#f11027"
                      ></path>{" "}
                    </svg>
                  </div>
                  <Link to="#" className="img-pnl ">
                    <img src={item.image} alt="Post" />
                  </Link>
                  <div className="txt-pnl">
                    <Link to="#">
                      <p className="post-title">{item.name}</p>
                      <div class="middel middel2">
                        <a onClick={toggleDrop} class="arrowdown">
                          Extra
                        </a>

                        <div
                          className={drop ? "ex-d mystyle" : "ex-d"}
                          style={{ display: "none" }}
                        >
                          {item.extra &&
                            item.extra.map((extra) => {
                              return <p style={{ height: "unset" }}>{extra}</p>;
                            })}
                        </div>
                      </div>
                    </Link>
                    <div className="flex-div">
                      <h3>AED {item.price}</h3>
                      <div>
                        <Button className="reg-btn small">Add To Cart</Button>
                      </div>
                      <div className="count-order-amount">
                        <Button className="small" onClick={handleIncrease}>
                          <i className="fa fa-minus"></i>
                        </Button>
                        <p>{count}</p>
                        <Button className=" small" onClick={handleDecrease}>
                          <i className="fa fa-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */
            <CartItem item={item}></CartItem>
          );
        })}
        {/* <div className="add-post-container v2 mt-4">
          <div className="add-cart-post payment v2">
            <div className="add-cart-post-inner">
              <div
                className={enableClass ? "animated-background bg-skimmer" : ""}
              ></div>
              <div className="cross-btn-red">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 23 23"
                >
                  <path
                    id="Icon_ionic-ios-close-circle"
                    data-name="Icon ionic-ios-close-circle"
                    d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                    transform="translate(-3.375 -3.375)"
                    fill="#f11027"
                  ></path>{" "}
                </svg>
              </div>
              <Link to="#" className="img-pnl ">
                <img src={item.img} alt="Post" />
              </Link>
              <div className="txt-pnl">
                <Link to="#">
                  <p className="post-title">{item.name}</p>
                  <div class="middel middel2">
                    <a onClick={toggleDrop} class="arrowdown">
                      Extra
                    </a>

                    <div
                      className={drop ? "ex-d mystyle" : "ex-d"}
                      style={{ display: "none" }}
                    >
                      <p>{item.extra}</p>
                    </div>
                  </div>
                </Link>
                <div className="flex-div">
                  <h3>AED 110.00</h3>
                  <div>
                    <Button className="reg-btn small">Add To Cart</Button>
                  </div>
                  <div className="count-order-amount">
                    <Button className="small" onClick={handleIncrease}>
                      <i className="fa fa-minus"></i>
                    </Button>
                    <p>{count}</p>
                    <Button className=" small" onClick={handleDecrease}>
                      <i className="fa fa-plus"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* 
          <div className="add-post-container">
          <div className="add-cart-post payment">
            <div className="add-cart-post-inner">
              <div
                className={enableClass ? "animated-background bg-skimmer" : ""}
              ></div>
              <button className="post-delet-btn">
                <i className="fa fa-times-circle-o"></i>
              </button>
              <Link to="#" className="img-pnl">
                <img src={Post} alt="Post" />
              </Link>
              <div className="txt-pnl">
                <Link to="#">
                  <h2>Arabic Breakfast</h2>
                </Link>
                <div className="flex-div">
                  <h3>AED 110.00</h3>
                  <div>
                    <Button className="reg-btn small">Add To Cart</Button>
                  </div>
                  <div className="count-order-amount">
                    <Button className="small" onClick={() => handleIncrease(2)}>
                      <i className="fa fa-minus"></i>
                    </Button>
                    <p>{count2}</p>
                    <Button
                      className=" small"
                      onClick={() => handleDecrease(2)}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*       <div className="full-div padd-div">
          <Link className="reg-btn big w-100 text-center" to="/">
            <i className="fa fa-plus-square"></i> Add More Items
          </Link>
        </div> */}
        {/*  <div className="voucher-panel">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.375"
              height="15.5"
              viewBox="0 0 19.375 15.5"
            >
              <path
                id="Icon_awesome-tags"
                data-name="Icon awesome-tags"
                d="M15.074,6.84,8.66.426A1.453,1.453,0,0,0,7.632,0H1.453A1.453,1.453,0,0,0,0,1.453V7.632A1.453,1.453,0,0,0,.426,8.66L6.84,15.074a1.453,1.453,0,0,0,2.055,0L15.074,8.9a1.453,1.453,0,0,0,0-2.055Zm-11.684-2A1.453,1.453,0,1,1,4.844,3.391,1.453,1.453,0,0,1,3.391,4.844ZM18.949,8.9,12.77,15.074a1.453,1.453,0,0,1-2.055,0l-.011-.011,5.269-5.269a2.725,2.725,0,0,0,0-3.853L10.033,0h1.475a1.453,1.453,0,0,1,1.028.426L18.949,6.84a1.453,1.453,0,0,1,0,2.055Z"
                fill="#006390"
              ></path>
            </svg>{" "}
            Promo code
          </h3>
          <div className="d-flex">
            <Form.Control type="text" placeholder="" />
            <Button className="reg-btn small">apply</Button>
          </div>
        </div> */}
        <div className="code-parent mb-4">
          <div class="code-section">
            <div class="left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.375"
                height="15.5"
                viewBox="0 0 19.375 15.5"
              >
                <path
                  id="Icon_awesome-tags"
                  data-name="Icon awesome-tags"
                  d="M15.074,6.84,8.66.426A1.453,1.453,0,0,0,7.632,0H1.453A1.453,1.453,0,0,0,0,1.453V7.632A1.453,1.453,0,0,0,.426,8.66L6.84,15.074a1.453,1.453,0,0,0,2.055,0L15.074,8.9a1.453,1.453,0,0,0,0-2.055Zm-11.684-2A1.453,1.453,0,1,1,4.844,3.391,1.453,1.453,0,0,1,3.391,4.844ZM18.949,8.9,12.77,15.074a1.453,1.453,0,0,1-2.055,0l-.011-.011,5.269-5.269a2.725,2.725,0,0,0,0-3.853L10.033,0h1.475a1.453,1.453,0,0,1,1.028.426L18.949,6.84a1.453,1.453,0,0,1,0,2.055Z"
                  fill="#006390"
                ></path>
              </svg>
              <p>Promo Code</p>
            </div>
            <div class="middel">
              <input type="text" name="code" required="" />
            </div>
            <div class="right">
              <button type="submit"> Apply</button>
            </div>
          </div>
        </div>
        <div className="full-heading discount flex m-0 mb-3">
          <h2>Discount</h2>
          <h2>AED 0.0</h2>
        </div>
        <div className="full-heading total flex mb-5">
          <h2>Order Total</h2>
          <h2>AED 66.4</h2>
        </div>
        <Form className="px-3 rounded-form">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              value={"Room number 345"}
              placeholder="Enter Your Room # (Optional)"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicname">
            <Form.Control type="text" placeholder="Your Name (Optional)" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicname">
            <Form.Control
              type="emial"
              placeholder="Enter Your Email (Optional)"
            />
          </Form.Group>

          {/*    <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Select
                className="form-control"
                aria-label="Default select example"
              >
                <option>Date & Time</option>
              </Form.Select>
            </Form.Group> */}

          <Form.Group className="mb-3 w-100">
            <PhoneInputComponent
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicname">
            <Form.Control
              type="emial"
              placeholder="Note/Special Request (Optional)"
            />
          </Form.Group>
        </Form>
        {/*   <div className="padd-div">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter Your Room # (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder="Enter Your Name (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicemail">
              <Form.Control
                type="email"
                placeholder="Enter Your Email (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder="Enter Your Number (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder="note/special request (Optional)"
              />
            </Form.Group>
          </Form>
        </div> */}
        <div className="full-heading payment flex">
          <h2>Payment Method</h2>
        </div>
        <PaymentOptionList />
        <div className="padd-div d-flex agree my-4">
          <Form.Check type="checkbox" label="" />
          <p>
            <Link>I agree toTerms & Conditions</Link>
          </p>
        </div>
        <div className="padd-div text-center">
          <Link className="reg-btn order-btn" to="/RestaurantOrderPlaced">
            order
          </Link>
        </div>
        <div className="spacer-40 my-5"></div>
      </main>
    </>
  );
}
export default RestaurantOrderPayment;
