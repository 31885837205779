import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarRentalPost from "../../Components/CarRentalPost";
import { useLocation, useNavigate } from "react-router-dom";
import CarRentalLoader from "../../Components/Loaders/CarRentalLoader";
import { useTranslation } from "react-i18next";
import Search from "../../Components/Search";
import { useInfiniteQuery } from "react-query";
import { CarsAPI } from "../../API/CarsAPI";
import ReactGA from "react-ga4";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Carosle from "../../Components/Carosle";

function CarsService() {
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [price, setPrice] = useState(null)

  const [year, setYear] = useState("");


  let carSettings = {
    carModels: [],
    carTypes: [],
    years_list: [],
  };
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    `cars-${lang}-${make?.id ? make?.id : ""}-${model?.id ? model?.id : ""
    }-${year}-${price}`,
    ({ pageParam = 1 }) =>
      CarsAPI({
        room_uuid,
        make: make?.id ? make?.id : "",
        model: model?.id ? model?.id : "",
        sort_direction: price ? `${price}&sort_by=price` : null,
        year,
        pageParam,
        lang,
      }),
    {
      getNextPageParam: (data) => {
        const lastPage = data?.data?.data?.cars?.last_page;
        const currentPage = data?.data?.data?.cars?.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );
  let banners
  let header_title
  if (!isLoading) {
    if (data.pages[0].data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      console.log("datadnjddjd", data);
      carSettings.carModels = data.pages[0]?.data.data.carModels;
      carSettings.carTypes = data.pages[0]?.data.data.carTypes;
      carSettings.years_list = data.pages[0]?.data.data.years_list;
      banners = data.pages[0]?.data.data.banners;
      header_title = data.pages[0]?.data.data.header_title;
    }
  }
  let loadMoreRef = useRef();

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
      {
        root: null,
        margin: "0px",
        treshold: 1.0,
      }
    );

    const el = loadMoreRef && loadMoreRef.current;

    if (!el) {
      return;
    }

    observer.observe(el);
  }, [loadMoreRef.current, hasNextPage]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  const new_design = localStorage.getItem("new_design");
  const theme = new_design === "true";
  return (
    <div className={theme ? "food pb-0" : ""}>
      {theme ? (
        <Title title={header_title} />
      ) : (
        <HeaderInner headerName={header_title} />
      )}
      <main className="home-main bg-white back-fe pad-top-0 car_rental">
        {isLoading ? (
          <CarRentalLoader />
        ) : (
          <>
            <Search
              placeholder="Search for A Car"
              room_uuid={room_uuid}
              category="Car Rental"
            />
            <Carosle
              className={"dot-bar less-margin small-dots"}
              headerImages={banners}
            />

            <div className="padd-div car-drop-flex flex-div">
              <Dropdown className="car-drop">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled={carSettings.carTypes.length === 0}
                >
                  {make.name ? make.name : `${t("Make")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setMake("")}>
                    {t("Make")}
                  </Dropdown.Item>
                  {carSettings.carTypes
                    ? carSettings.carTypes.map((type) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            ReactGA.event({
                              category: "Select Make in car page",
                              action: "Click",
                              value: `make: ${type.name}`,
                            });
                            setMake(type);
                          }}
                        >
                          {type.name}
                        </Dropdown.Item>
                      );
                    })
                    : null}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="car-drop">
                <Dropdown.Toggle
                  variant="success"
                  disabled={carSettings.carModels.length === 0}
                  id="dropdown-basic"
                >
                  {model.name ? model.name : `${t("Model")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setModel("")}>
                    {t("Model")}
                  </Dropdown.Item>
                  {carSettings.carModels
                    ? carSettings.carModels.map((model) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            setModel(model);
                            ReactGA.event({
                              category: "Select Model in car page",
                              action: "Click",
                              value: `make: ${model.name}`,
                            });
                          }}
                        >
                          {model.name}
                        </Dropdown.Item>
                      );
                    })
                    : null}
                </Dropdown.Menu>


              </Dropdown>
              <Dropdown className="car-drop">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                >
                  {price ? price == "asc" ? "Low to high" : "High to low" : `${t("Price")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>

                  <Dropdown.Item onClick={() => setPrice("asc")}>
                    {t("Low to high")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPrice("desc")}>
                    {t("High to low")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </div>
            <div className="spacer-40 "></div>

            <ul className="display-list car-list two-items v2">
              {data?.pages &&
                data.pages.map((page, pageIndex) => {
                  // Convert the object to an array
                  const carsArray = Object.values(page.data?.data?.cars?.data || {});
                  return carsArray.map((item, itemIndex) => (
                    <CarRentalPost key={`${pageIndex}-${itemIndex}`} car={item} room_uuid={room_uuid} />
                  ));
                })}
            </ul>
            <div
              className="ref_loading"
              ref={loadMoreRef}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            ></div>
            <div>
              {isFetchingNextPage ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              ) : hasNextPage ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default CarsService;
