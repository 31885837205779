import React from "react";
import { Carousel } from "react-responsive-carousel";
import Slide1 from "../assets/images/Laundry/Laundry.png";
import Slide2 from "../assets/images/Laundry/Laundry-1.png";
function LaundryCarosle({ headerImages }) {
  const images = [Slide1, Slide2];
  return (
    <div className="dot-bar">
      <Carousel
        className="mb-5 noSelect"
        swipeable={true}
        autoPlay={true}
        infiniteLoop={true}
        showIndicators={true}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
        showStatus={false}
      >
        {headerImages
          ? headerImages.map((slide) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={slide} alt="Slide" style={{ height: "100%" }} />
              </div>
            );
          })
          : images.map((slide) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={slide} alt="Slide" />
              </div>
            );
          })}
      </Carousel>
    </div>
  );
}
export default LaundryCarosle;
