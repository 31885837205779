import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Slide1 from "../assets/images/slide-1.png";
import Slide2 from "../assets/images/slide-2.png";
import Slide3 from "../assets/images/slide-3.png";
import Slide4 from "../assets/images/slide-4.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
function SupermarketCarosle({ className, headerImages }) {
  const [intervalz, setIntervalz] = useState(3000); //initial state here represents the interval for first image.

  const onChange = (index, item) => {
    setIntervalz(item.props["data-interval"]);
  };
  // console.log("headerImages", headerImages);

  return (
    <div className={className}>
      <Carousel
        className=" noSelect mb-2"
        swipeable={true}
        onChange={onChange}
        autoPlay
        interval={intervalz}
        infiniteLoop={true}
        showIndicators={true}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
      >
        {headerImages?.map((slide) => {
          return (
            <div className="carousel-item-custom noSelect" data-interval={3000}>
              <img src={slide} alt="Slide" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
export default SupermarketCarosle;
