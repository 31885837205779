import React from "react";
import { Carousel } from "react-responsive-carousel";
import Slide2 from "../assets/images/slide-2.png";
import Slide3 from "../assets/images/slide-3.png";
import Slide4 from "../assets/images/slide-4.png";
import HouseImage from "../assets/images/housekeeping.jpeg";
import Fimage from "../assets/images/feedback.jpg";
import Meeting from "../assets/images/MeetingEQ.jpg";
import Stationary from "../assets/images/Stationary.jpg";

function SpaCarosle({
  noDots,
  autoPlay,
  isHouseKeeping,
  className,
  isFeedback,
  isSalon,
  salonImg,
  meeting,
  stationary,
}) {
  const has_extra_services = localStorage.getItem("has_extra_services");
  let houseKeepingSlides;
  if (has_extra_services == "true") {
    houseKeepingSlides = [Stationary];
  } else {
    houseKeepingSlides = [HouseImage, HouseImage];
  }
  const spaSlides = [Slide2, Slide3, Slide4];
  const meetingSlider = [Meeting];
  const stationarySlider = [Stationary];

  return (
    <div className={className}>
      <Carousel
        className={
          isHouseKeeping || isSalon ? "mb-3 noSelect" : "mb-5 noSelect"
        }
        swipeable={true}
        autoPlay={autoPlay ? false : true}
        infiniteLoop={true}
        showIndicators={noDots ? false : true}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
        showStatus={false}
      >
        {isFeedback ? (
          <div className="carousel-item-custom noSelect">
            <img src={Fimage} alt="Slide" />
          </div>
        ) : isHouseKeeping ? (
          houseKeepingSlides?.map((silde) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={silde} alt="Slide" />
              </div>
            );
          })
        ) : isSalon ? (
          salonImg?.map((silde) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={silde} style={{ height: "212px" }} alt="Slide" />
              </div>
            );
          })
        ) : meeting ? (
          meetingSlider.map((silde) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={silde} style={{ height: "212px" }} alt="Slide" />
              </div>
            );
          })
        ) : stationary ? (
          stationarySlider.map((silde) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={silde} style={{ height: "212px" }} alt="Slide" />
              </div>
            );
          })
        ) : (
          spaSlides.map((slide) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={slide} alt="Slide" />
              </div>
            );
          })
        )}
      </Carousel>
    </div>
  );
}
export default SpaCarosle;
