import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainments } from "../../API/EntertainmentAPI";
import { ShowHH_HouseKeepings } from "../../API/HH_HouseKeepingAPI";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import { ThemeContext } from "../../context/ThemeContext";

function HH_HouseKeepings() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const euuid = rawPath.split("categories/")[1];
  const navigate = useNavigate();
  const params = useParams();
  // const theme = location.state?.theme;
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const lang = localStorage.getItem("lang");

  let flowers;
  let bgColor;
  let color;
  let text;
  let company_uuid;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowHH_HouseKeepings(
    room_uuid,
    params.uuid,
    lang
  );
  if (!isLoading) {
    console.log("ShowHH_HouseKeepingsShowHH_HouseKeepings", data);
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        entertainmentCategories = flowers.houseKeepingServices;
        color = flowers.color;
        bgColor = flowers.header_color;
        text = flowers.title;
        company_uuid = flowers?.company_uuid;

        // console.log("ShowEntertainmentss", flowers);
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios
  //       .get(
  //         `/${room_uuid}/entertainment/categories/1bb893d8-e5b0-42ba-a684-7f563c2e8ca5`
  //       )
  //       .then((res) => {
  //         const data = res.data.data;
  //         setEntertainmentCategories(data.entertainment_categories);
  //         setTitle(data?.title)
  //         console.log("data,entertainment/companies", data);
  //         setLoading(false)
  //       });
  //   };
  //   getData();
  // }, []);

  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white back-white pad-top-0">
        <div className="">
          {isLoading ? (
            <SpaLoader />
          ) : theme ? (
            <div className="box_food">
              {entertainmentCategories?.map((item, idx) => {
                return (
                  <span
                    key={idx}
                    onClick={() =>
                      navigate(
                        `/${room_uuid}/HH_HouseKeepingDetail/${company_uuid}/${item.id}`,
                        {
                          state: { theme: true },
                        }
                      )
                    }
                  >
                    <div style={{ position: "relative" }}>
                      <img src={item?.image} alt="" className="booking_img" />
                      <img src={Overllay} alt="" className="overlay" />
                      <div className="text_food">
                        <h5 className="">{item.name}</h5>
                      </div>{" "}
                    </div>
                  </span>
                );
              })}
            </div>
          ) : entertainmentCategories ? (
            entertainmentCategories.map((x, idx) => {
              return (
                <div id={x.name} key={idx}>
                  <Zoom>
                    <div
                      className="con-img-container v3"
                      onClick={() =>
                        navigate(
                          `/${room_uuid}/HH_HouseKeepingDetail/${company_uuid}/${x.id}`
                        )
                      }
                    >
                      <div className="con-img">
                        <img src={x.image} alt="" />
                        <p></p>
                      </div>
                    </div>
                    <div className="con-text-container">
                      <p>{x.name}</p>
                      <hr className="mb-0 dark" />
                    </div>
                  </Zoom>
                </div>
              );
            })
          ) : null}
        </div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HH_HouseKeepings;
