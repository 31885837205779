import React from "react";
import { useTranslation } from "react-i18next";

const Title = ({ des }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="section-populer ">
      <p>{t(`${des}`)} </p>
    </div>
  );
};

export default Title;
