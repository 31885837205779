import React from 'react'
import { Link } from 'react-router-dom'

const RestaurantsLoader = () => {
    return (
        <>
            <div className="general-post-container restaurant-post">
                <Link
                    to=""
                    className="general-post"
                >
                    <div className="closed-tag"></div>
                    <div
                        className={
                            "img-pnl animated-background smooth"
                        }
                    >
                        <img
                            src=""
                            alt="Post"
                            className=""
                        />
                    </div>
                    <div className="txt-pnl">
                        <h2 className="animated-background">
                        </h2>
                        <p className="animated-background">
                        </p>
                    </div>
                </Link>
            </div>
            <div className="general-post-container restaurant-post">
                <Link
                    to=""
                    className="general-post"
                >
                    <div className="closed-tag"></div>
                    <div
                        className={
                            "img-pnl animated-background smooth"
                        }
                    >
                        <img
                            src=""
                            alt="Post"
                            className=""
                        />
                    </div>
                    <div className="txt-pnl">
                        <h2 className="animated-background">
                        </h2>
                        <p className="animated-background">
                        </p>
                    </div>
                </Link>
            </div>
            <div className="general-post-container restaurant-post">
                <Link
                    to=""
                    className="general-post"
                >
                    <div className="closed-tag"></div>
                    <div
                        className={
                            "img-pnl animated-background smooth"
                        }
                    >
                        <img
                            src=""
                            alt="Post"
                            className=""
                        />
                    </div>
                    <div className="txt-pnl">
                        <h2 className="animated-background">
                        </h2>
                        <p className="animated-background">
                        </p>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default RestaurantsLoader