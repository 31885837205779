import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import axios from "../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Title from "../../new-components/title/Title";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Rating } from "primereact/rating";
import NewFooter from "../../NewFooter";
import SpaLoader from "../../Components/Loaders/SpaLoader";

function Feedback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const new_design = localStorage.getItem("new_design");
  const theme = new_design === "true";
  const lang = localStorage.getItem("lang");

  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [feedbackForm, setFeedbackForm] = useState([]);
  const [formServices, setFormServices] = useState([]);
  const [message, setMessage] = useState(null);
  const [title, setTitle] = useState("");
  // const [businessType, setBusinessType] = useState("");
  const [information, setinformation] = useState();
  const [improvementSuggestion, setImprovementSuggestion] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRatingChange = (formIndex, questionIndex, value) => {
    const updatedFormServices = [...formServices];
    updatedFormServices[formIndex].questions[questionIndex].rate = value;
    setFormServices(updatedFormServices);
  };

  const handleNoteChange = (formIndex, value) => {
    const updatedFormServices = [...formServices];
    updatedFormServices[formIndex].note = value;
    setFormServices(updatedFormServices);
  };

  const allRatingsAboveEight = () => {
    const filteredData = formServices.map(form => {
      const filteredQuestions = form.questions.filter(q => q.rate !== 0);
      return { ...form, questions: filteredQuestions };
    }).filter(form => form.questions.length > 0);

    if (filteredData.length > 0) {

      return filteredData.every((form) =>
        form.questions.every((question) => question.rate >= 8)
      );
    } else {
      return false
    }
  };


  const allRatingsEqualZero = () => {
    return formServices.every((form) =>
      form.questions.every((question) => question.rate === 0)
    );
  };
  const allNoteDontEmpty = () => {
    return formServices.some(form => form.note.trim() !== "");
  };


  // console.log("allRatingsEqualZero", allRatingsEqualZero());
  // console.log("allNoteDontEmpty", !allNoteDontEmpty());

  const clickOnAdvOrGoogle = (type) => {
    axios
      .post(
        `/${room_uuid}/feedback/click`,
        { link_type: type },
        {
          headers: { lang },
        }
      )
      .then((res) => {
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }

  const handleSendFeedback = (e) => {
    e.preventDefault();
    setLoadingForm(true);
    const formObjectWithMessage = {
      form_services: formServices,
      message: message,
      improvement_suggestion: improvementSuggestion,
    }
    const formObjectWithOutMessage = {
      form_services: formServices,
      improvement_suggestion: improvementSuggestion,
    }

    if (allRatingsAboveEight()) {
      setOpen(true);
    } else {
      navigate(`/${room_uuid}/qr-code`);
      toast.success(t(`Thank you, we’ve received your feedback and will work diligently to enhance our services.`), {
        className: theme ? 'custom-toast' : '',
        bodyClassName: theme ? 'custom-toast' : '',
        progressClassName: theme ? 'custom-toast' : '',
      });

    }
    axios
      .post(
        `/${room_uuid}/feedback/send-feedback`,
        message ? formObjectWithMessage : formObjectWithOutMessage,
        {
          headers: { lang },
        }
      )
      .then((res) => {
        if (res.data.status) {
          ReactGA.event({
            category: "Send Feedback",
            action: "Click",
            value: "",
          });
        }
        setLoadingForm(false);
        setMessage("");
        setImprovementSuggestion("");


      })
      .catch((err) => {
        console.error("Error:", err);
        setLoadingForm(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    axios.get(`/${room_uuid}/feedback`, { headers: { lang: lang } }).then((res) => {
      if (res.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        const data = res.data.data;
        setTitle(data.title);
        setFeedbackForm(data.form);
        // setBusinessType(data.businessType);
        setinformation(data?.feedbackSettings[0]);
        setFormServices(
          data.form.map((item) => ({
            form_id: item.id,
            questions: item.questions.map((q) => ({
              question_id: q.id,
              rate: 0,
            })),
            note: "",
          }))
        );
        setLoading(false);
      }
    });
  }, [room_uuid, lang, navigate]);

  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={title} />
      ) : (
        <HeaderInner headerName={title} />
      )}
      {loading ? (
        <SpaLoader />
      ) : (
        <main className="home-main pad-top-0 back-white feedBack_page" style={{ minHeight: "auto" }}>
          <p className="text_feed px-4">
            {t("Thank you for choosing to stay with us. We value your feedback and would appreciate if you could take a few minutes to share your experience.")}
          </p>
          <form onSubmit={handleSendFeedback}>
            <Accordion
              expandIcon="fa fa-plus"
              collapseIcon="fa fa-minus"
              className="mb-4"
            >
              {feedbackForm.map((item, formIndex) => (
                <AccordionTab
                  key={formIndex}
                  header={
                    <span className="feedback_icon">
                      <img src={item.icon} alt="" />
                      <p>{item.title}</p>
                    </span>
                  }
                  disabled={false}
                >
                  <ul className="rating_list">
                    {item.questions.map((question, questionIndex) => (
                      <li key={question.id}>
                        <p className="rating_title">{question.question}</p>
                        <div className="rating_box">
                          <Rating
                            value={
                              formServices[formIndex]?.questions[questionIndex]
                                .rate
                            }
                            onChange={(e) =>
                              handleRatingChange(
                                formIndex,
                                questionIndex,
                                e.value
                              )
                            }
                            cancel={false}
                            stars={10}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                  {item.with_note === 1 && (
                    <Form.Group className="mb-3 w-100">
                      <Form.Control
                        as="textarea"

                        value={formServices[formIndex]?.note}
                        onChange={(e) =>
                          handleNoteChange(formIndex, e.target.value)
                        }
                        placeholder={t("Note (optional)")}
                        style={{ height: "70px" }}
                      />
                    </Form.Group>
                  )}
                </AccordionTab>
              ))}
            </Accordion>
            <div className="px-2">
              <Form.Group className="mb-3 w-100">
                <Form.Control
                  as="textarea"
                  required={allRatingsEqualZero() && !allNoteDontEmpty()}

                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={t(
                    "Is there anything else you would like to share about your stay?"
                  )}
                  style={{ height: "70px" }}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100">
                <Form.Control
                  as="textarea"
                  value={improvementSuggestion}
                  onChange={(e) => setImprovementSuggestion(e.target.value)}
                  required={allRatingsEqualZero() && !allNoteDontEmpty()}
                  placeholder={t(
                    "How can we improve your experience in the future?"
                  )}
                  style={{ height: "70px" }}
                />
              </Form.Group>
            </div>
            <div className="padd-div text-center mb-2 ">
              <Button
                className="reg-btn order-btn"
                type="submit"
                // onClick={handleSendFeedback}
                disabled={loadingForm}
              >
                {t("Submit")}
              </Button>
            </div>
          </form>
        </main>
      )}
      {theme && <NewFooter />}
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        className={`${theme ? "food-modal rate_modal" : ""} rate_modal p-0`}
        style={{
          minHeight: "150px",
          padding: "0px !important",
          display: "flex !important",
          alignItems: "center !important",
          paddingRight: "0 !important",
        }}
      >
        <Modal.Body style={{ padding: "20px 10px", height: "auto" }}>
          <span className="check_feed">
            <i className="fas fa-check"></i>
          </span>

          <h5 className="feed_done">{t("Done")}</h5>
          <p className="text_feed">
            {t("Thank you for your feedback! We hope to welcome you again soon.")}
            <br />{t("Please rate us on")}
          </p>
          {allRatingsAboveEight() ? (
            <div className="feed_logos">
              {
                information?.trip_advisor &&

                <a
                  onClick={() => clickOnAdvOrGoogle("trip_advisor")}
                  target="_blank"
                  href={information?.trip_advisor}
                  className=""
                >
                  <img src={"https://d3l5wxnahfuscp.cloudfront.net/fed_trip_b.png"} alt="" />
                </a>
              }
              {
                information?.review_link &&
                <a
                  onClick={() => clickOnAdvOrGoogle("google")}
                  target="_blank"
                  href={information?.review_link}
                  className=""
                >
                  <img src={"https://d3l5wxnahfuscp.cloudfront.net/fed_google_b.png"} alt="" />
                </a>
              }
            </div>
          ) : (
            <div className="feed_btns">
              <a
                href={`tel:${information?.manager_number}`}
                className="feed_btns"
                target="_blank"
              >

                Contact the Manager
              </a>
            </div>
          )}
          <div className="feed_btns">
            <button
              onClick={() => navigate(`/${room_uuid}/qr-code`)}
              className="feed_btns"
            >
              {t("Back to Home")}

            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Feedback;
