import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SPASearch = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const spa_uuid = rawPath.split("/spa/")[1];
  // console.log("spa_uuid", spa_uuid);
  const lang = localStorage.getItem("lang");
  const currency = localStorage.getItem("currency");

  const { t, i18n } = useTranslation();
  return (
    <div className="add-post-container add-cart">
      <div className="add-cart-post new spa ">
        <div className="">
          <Link
            to={`/${room_uuid}/spa/${item.spa_uuid}/service/${item.id}`}
            state={item.id}
            className="add-cart-post-inner"
          >
            <span className="img-pnl navigate">
              <img src={item.image} alt="Post" />
            </span>
            <div
              className="txt-pnl navigate"
              // onClick={() => navigate("/SpaAddService")}
            >
              <h2>{item.name}</h2>
              <p></p>
              <div className="flex-div align-item-center">
                <h3>
                  {`${currency} 
                  ${item.price}`}
                </h3>
                <span className="reg-btn service">{t("Add Service")}</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SPASearch;
