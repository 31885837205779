import React, { useContext, useEffect, useRef, useState } from "react";
import Iframe from "react-iframe";
import Title from "../../new-components/title/Title";
import HeaderInner from "../../Components/HeaderInner";
import NewFooter from "../../NewFooter";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { ThemeContext } from "../../context/ThemeContext";

function SiteMapLinkstem() {
  const [urlParam, setUrlParam] = useState("");
  const [title, setTitle] = useState("");
  const [item_id, setitem_id] = useState("");
  const location = useLocation();
  const metadata = location.state?.metadata;

  console.log("metadata", metadata);


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get("url");
    const title_ = queryParams.get("title");
    const item_id_ = queryParams.get("item_id");
    setUrlParam(url);
    setTitle(title_);
    setitem_id(item_id_);
  }, []);





  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);


  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };


  const pdfUrl = "https://d1xx0o2taatuie.cloudfront.net/QC+Directional+Maps.pdf";

  // Create an instance of the default layout plugin

  const parseHTMLToText = (html) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };


  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={title} />
      ) : (
        <HeaderInner headerName={title} />
      )}
      <main className="home-main bg-white back-white book-table pad-top-0 taxi_book thing_to">
        {/* {metadata &&
          <div className="div-des">
            <p>{t(`${parseHTMLToText(metadata)}`)}</p>
          </div>
        } */}
        {

          <img src={encodeURI(urlParam)} alt="" style={{ height: "80vh", width: "100%" }} />

        }

      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default SiteMapLinkstem;
